.video-player-img-div {
	min-height: 659px !important;
}

.video-react {
	height: 600px !important;
}

.sedusa-overlay-container {
	position: relative;
}

.sedusa-overlay-visible {
	background-color: rgba(0, 0, 0, 0.5);     /* Adjust the background color for transparency */
	opacity: 0.5;     /* Adjust the opacity to make the underlying component less visible */
}

.sedusa-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);     /* Black overlay with 50% opacity */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.sedusa-overlay-content {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	width: 51rem;
	height: 30rem;
	background: #141d30;
	border-radius: 10px;
}

.sedusa-overlay button {
	margin-top: 10px;
}

.sedusa-modal {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
}

.sedusa-modal-content {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 20px;
}

.sedusa-modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 10px;
	border-bottom: 1px solid #ccc;
}

.sedusa-modal-header h4 {
	margin: 0;
}

.sedusa-btn-close {
	background: none;
	border: none;
	cursor: pointer;
	font-size: 20px;
}

.sedusa-modal-body {
	padding-top: 10px;
}

/* #v_player_trailer {
	width: 100%;
	height: auto;
} */
.video-react .video-react-control-bar .video-react-progress-control .video-react-slider .custom-progress-bar {
	background-color: blue;
}