.customSocialIcon {
	width: 20px !important;
	height: 34px !important;
	border-radius: 50%;
	transition: background-color 0.2s ease-in-out;
}

.customSocialIcon:hover {
	background-color: #d31027 !important;
	border-radius: 50%;
}