.preloader {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 99999 !important;
	background: radial-gradient(closest-corner, #2a1c50 20%, #180d36 60%, #0d0620 100%);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.preloader .ldsEllipsis {
	display: inline-block;
	position: absolute;
	width: 80px;
	height: 80px;
	margin-top: -40px;
	margin-left: -40px;
	top: 50%;
	left: 50%;
}

.preloader .ldsEllipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: linear-gradient(60deg, #eaa538, #d31027);
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.preloader .ldsEllipsis div:nth-child(1) {
	left: 8px;
	animation: ldsEllipsis1 0.6s infinite;
}

.preloader .ldsEllipsis div:nth-child(2) {
	left: 8px;
	animation: ldsEllipsis2 0.6s infinite;
}

.preloader .ldsEllipsis div:nth-child(3) {
	left: 32px;
	animation: ldsEllipsis2 0.6s infinite;
}

.preloader.preloader-dark {
	background-color: #000;
}

.preloader.preloader-dark .ldsEllipsis div {
	background-color: #fff;
}

@keyframes ldsEllipsis1 {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}

}

@keyframes ldsEllipsis3 {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0);
	}

}

@keyframes ldsEllipsis2 {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(24px, 0);
	}

}

.header-section ul li:hover > a {
	color: #d31027;
}

header .modal-content {
	border-radius: 0;
}

header .modal-header {
	background-color: #f44336;
	padding: 0;
}

header .modal-header h3 {
	color: #fff;
	font-size: 20px;
	font-weight: 400;
	padding: 10px 15px;
	text-transform: capitalize;
	height: 50px;
	line-height: 29px;
	border: none !important;
}

header .modal-header .close-btn {
	background-color: #d93d3d;
	border: medium none;
	border-radius: 0;
	color: #fff;
	font-size: 33px;
	height: 50px;
	line-height: 33px;
	margin: 0;
	width: 40px;
}

header .modal-body .btn.btn-primary {
	background-color: #f44336;
	border: medium none;
	border-radius: 4px;
	font-size: 18px;
	min-width: 116px;
}

#social-media .modal-header .modal-title {
	font-size: 20px;
	font-weight: 600;
}

.modal-content {
	border: none;
}

.modal-header {
	border-bottom: 1px solid #25324c;
	padding: 1rem 1.5rem;
}

.modal-body {
	padding: 1.5rem;
}

.modal-backdrop.show {
	background: rgba(6, 8, 12, 1);
	opacity: 0.9;
}

.modal-content {
	background: #141d30;
}

.modal-dialog .btn-close {
	box-sizing: content-box;
	width: 26px;
	height: 26px;
	padding: 3px;
	color: #ffffff;
	background: #e8e8e8 url("data:image/svg+xml,%3csvg !string!!string!!string!%3e%3cpath !string!/%3e%3c/svg%3e") center/1em auto no-repeat;
	border: 0;
	border-radius: 30px;
	opacity: .9;
	margin-right: 0;
}

.social-media-modal {
	display: inlin-block;
	text-align: center;
}

.social-media-modal ul li {
	display: inline-block;
}

.social-media-modal ul li a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 42px;
	text-align: center;
	margin: 3px;
	border-radius: 50%;
	font-size: 18px;
	color: #ffffff;
	position: relative;
	-webkit-transition: all .4s linear 0s;
	transition: all .4s linear 0s;
}

.social-media-modal ul li a.facebook-icon {
	background: #4267b2;
}

.social-media-modal ul li a.twitter-icon {
	background: #00acee;
}

.social-media-modal ul li a.instagram-icon {
	background: #8a3ab9;
}

.social-media-modal ul li a.pinterest-icon {
	background: #E60023;
}

.social-media-modal ul li a.youtube-icon {
	background: #c4302b;
}

.social-media-modal ul li a.whatsapp-icon {
	background: #4fce5d;
}

.social-media-modal ul li a:hover {
	background: #ffffff;
	color: #323232;
}

#trailerModel.stripe-payment-block .modal-body {
	padding: 0 15px;
}

#trailerModel.stripe-payment-block .modal-body #v_player_trailer {
	border-radius: 10px;
}

.dtl-title-block {
	position: relative;
}

.dtl-title-block h2, .dtl-title-block h2 a {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 85%;
}

.dtl-title-block .video-watch-share-item {
	text-align: right;
	float: right;
	position: absolute;
	right: 0;
	margin-top: 0;
}

.dtl-title-block .video-watch-share-item .subscribe-btn-item {
	margin: 0;
}

.eupopup-container {
	background-color: rgba(19, 10, 45, 0.98) !important;
	box-shadow: 0 0px 18px 0 rgba(255, 255, 255, 0.12) !important;
}

.eupopup-closebutton {
	background: #d31027 !important;
}

.eupopup-closebutton:hover, .eupopup-closebutton:active {
	background: #fff !important;
	color: #d31027 !important;
}

.eupopup-button_1, .eupopup-button_2 {
	color: #d31027 !important;
}

.eupopup-button_1:hover, .eupopup-button_2:hover {
	color: #ffffff !important;
}