.sedusa-profile-wrapper {
	min-height: 80vh;
	background-color: #0d0620;
}

.sedusa-profile-wrapper .sedusa-profile-header {
	height: 160px;
	opacity: 0.6;
	background-image: url('../../../../public/images/breadcrum-bg.jpg');
}

.sedusa-profile-wrapper .sedusa-profile-header .sedusa-profile-title {
	justify-content: center;
	display: flex;
	align-items: center;
	font-weight: 700;
	color: white;
	font-size: 33px;
	display: flex;
	justify-content: center;
}

.sedusa-profile-wrapper .sedusa-profile-body {
	display: flex;
	margin-top: 15px;
	justify-content: center;
	align-items: center;
	min-height: 60vh;
}

.sedusa-profile-wrapper .sedusa-profile-body .sedusa-profile-card {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 300px;
	border-radius: 5px;
	background-color: wheat;
	margin: 20px;
	background: url("../../../../public/images/plan_shape_bg.png") #130a2d;
}

.sedusa-profile-wrapper .sedusa-profile-body .sedusa-profile-card .sedusa-profile-card-header {
	background: linear-gradient(90deg, #eaa538, #d31027);
	padding: 12px 10px;
	width: 100%;
	text-align: center;
	font-size: 20px;
	margin-bottom: 20px;
	color: #ffffff;
	font-weight: 700;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.sedusa-profile-wrapper .sedusa-profile-body .sedusa-profile-card .sedusa-profile-card-price {
	font-size: 38px;
	font-weight: 700;
	text-align: center;
	position: relative;
}

.sedusa-profile-wrapper .sedusa-profile-body .sedusa-profile-card .sedusa-profile-card-price::before {
	background: #d31027;
	border: 0 none;
	content: "";
	height: 4px;
	left: 0;
	position: absolute;
	bottom: -3px;
	width: 45px;
	border-radius: 4px;
	right: 0;
	margin: 0 auto;
}

.sedusa-profile-wrapper .sedusa-profile-body .sedusa-profile-card .sedusa-profile-card-price .sedusa-profile-card-rupay {
	font-size: 27px;
	font-weight: 700;
	text-align: center;
}

.sedusa-profile-wrapper .sedusa-profile-body .sedusa-profile-card .sedusa-profile-card-duration {
	font-size: 17px;
	font-weight: 600;
	text-align: center;
	margin: 14px;
}

.sedusa-profile-wrapper .sedusa-profile-body .sedusa-profile-card button {
	background: linear-gradient(90deg, #eaa538, #d31027);
	margin: 20px;
	width: 130px;
	padding: 8px;
	font-size: 15px;
	color: white;
	font-weight: 700;
	border-radius: 7px;
	transition: all 0.5s ease;
	border-style: none;
}

.sedusa-profile-wrapper .sedusa-profile-body .sedusa-profile-card button:hover {
	background: linear-gradient(90deg, #d31027, #eaa538);
	margin: 20px;
	width: 130px;
	padding: 8px;
	font-size: 15px;
	color: white;
	font-weight: 700;
	border-radius: 9px;
}

.sedusa-profile-wrapper .sedusa-profile-body .sedusa-profile-card {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media all and (max-width:980px) {
	.sedusa-profile-wrapper .sedusa-profile-body {
		flex-wrap: wrap;
	}

}