.sesusa-category-details {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.sedusa-category-img {
	width: 267px !important;
	height: 313px !important;
}

.sedusa-category-breadcrumb {
	background-image: url("../../../public/images/breadcrum-bg.jpg");
}