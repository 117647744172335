.splide-slider-details-area img {
	width: 1200px
}

.customStyle {
	width: auto;
	height: auto !important;
	max-width: 220px;
}

.custom-splide {
	max-width: inherit;
	width: 100%;
	max-width: 800px;
	height: 400px;
}

.custom-slide {
	width: 100%;
	height: 100%;
	overflow: hidden;
	color: #333;
}

@media only screen and (max-width:1200px) {
	.customStyle {
		height: 253px !important;
		width: 14rem !important;
	}

	.owl-img-wrapper, .owl-img-div, .owl-img {
		height: 100%;
	}

}

.splide__arrow {
	background: linear-gradient(60deg, #eaa538, #d31027) !important;
	color: white !important;
	transition: all 0.4s linear 0s;
	opacity: 11 !important;
	top: 50% !important;
}

.splide__arrow:hover {
	background: #372d52 !important;
}

.splide__arrow svg {
	fill: white;
}

div.splide__track.splide__track--slide .splide__track--ltr .splide__track--draggable {
	padding-left: 0px !important;
	padding-left: 0px !important;
}

@media only screen and (max-width:600px) {
	.customStyle {
		height: 253px !important;
		width: 160px !important;
	}

	.customStyle2 {
		height: 253px !important;
		width: 100% !important;
	}

}

@media only screen and (min-width:601px) and (max-width:900px) {
	.customStyle {
		height: 253px !important;
		width: auto !important;
	}

}

@media only screen and (min-width:901px) {
	.banner-top {
		padding: 0 !important;
		margin-top: -68px;
	}

	.banner-top .splide__track::before .splide__track::after {
		content: "";
		width: 9%;
		height: 100%;
		position: absolute;
		right: 0;
		bottom: 0;
		background: linear-gradient(to right, rgba(255, 0, 0, 0), rgba(12, 6, 32, 1));
		background: linear-gradient(to right, rgba(255, 0, 0, 0), rgba(12, 6, 32, 1));
		opacity: 1;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		transition: all .4s linear 0s;
		transform-origin: right 0 0;
		z-index: 1;
	}

}

.slick-slider {
	display: table;
	table-layout: fixed;
	width: 100%;
}