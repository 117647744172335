.trailer-player-img-div {
	min-height: 659px !important;
}

.trailer-react {
	height: 600px !important;
}

.sedusa-overlay-container {
	position: relative;
}

.sedusa-overlay-visible {
	background-color: rgba(0, 0, 0, 0.5);     /* Adjust the background color for transparency */
	opacity: 0.5;     /* Adjust the opacity to make the underlying component less visible */
}

.sedusa-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);     /* Black overlay with 50% opacity */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.sedusa-overlay-content {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	width: 51rem;
	height: 34rem;
	background: #141d30;
	border-radius: 10px;
}

.sedusa-overlay button {
	margin-top: 10px;
}

.sedusa-modal {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
}

.sedusa-modal-content {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 20px;
}

.sedusa-modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 10px;
	border-bottom: 1px solid #ccc;
}

.sedusa-modal-header h4 {
	margin: 0;
}

.sedusa-btn-close {
	background: none;
	border: none;
	cursor: pointer;
	font-size: 20px;
}

.sedusa-modal-body {
	padding-top: 10px;
}

.sedusa-trailer-area {
	min-height: 400px !important;
}

.sedusa-trailer-area {
	max-height: 500px !important;
}

.sedusa-trailer-area .video-react .video-react-video {
	height: 52% !important;
}

.sedusa-modal-title {
	height: 20px;     /* padding: 2rem; */
	font-size: 22px;
	color: white;
	font-weight: 700;
	margin: 2rem;
	display: flex;
	justify-content: space-between;
}

.sedusa-modal-title button {

	/* color: ; */
	width: 2rem;
	height: 2rem;
	border-radius: 50%;     /* position: relative; */

	/* place-items: center; */
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width:500) {
	.sedusa-overlay-content {
		background-color: white;
		padding: 20px;
		border-radius: 5px;
		width: 51rem;
		height: 21rem;
		background: #141d30;
		border-radius: 10px;
	}

}

/* #v_player_trailer {
	width: 100%;
	height: auto;
} */