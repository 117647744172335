@media(min-width:1170px) and (max-width:1400px) {
	.header-section ul li ul.dm-align-2 {
		left: 0;
	}

	.video-posts-video .embed-responsive {
		height: 550px;
	}

}

@media only screen and (min-width:1366px) {
	.container-fluid.video-player-base {
		max-width: calc(100vw - 0px);
		width: 100%;
	}

}

@media only screen and (min-width:1365px) {
	.container-fluid {
		max-width: 1350px;
		width: 100%;
	}

	.video-js.vjs-16-9 {
		padding-top: 43% !important;
	}

}

@media only screen and (min-width:1200px) and (max-width:1364px) {
	.video-js.vjs-16-9 {
		padding-top: 39.25% !important;
	}

	.video-posts-video .embed-responsive {
		height: 460px;
	}

}

@media only screen and (max-width:1130px) {
	.download-app-link-item .google-play-download {
		margin-bottom: 15px;
	}

}

@media only screen and (min-width:768px) and (max-width:1340px) {
	.socail-login-item, .socail-login-item label {
		display: inline-block;
		width: 100%;
	}

	.socail-login-item .btn-facebook-item, .socail-login-item .btn-g-plus-item {
		margin-left: 0;
		margin-right: 0;
	}

}

@media only screen and (min-width:992px) and (max-width:1130px) {
	.video-shows-carousel .single-video .video-img img {
		height: 180px;
		object-fit: cover;
	}

	.video-posts-video .embed-responsive {
		height: 450px;
	}

}

@media only screen and (min-width:992px) and (max-width:1199px) {
	.video-js.vjs-16-9 {
		padding-top: 45% !important;
	}

}

@media only screen and (min-width:992px) and (max-width:1200px) {
	ul.nav > li:hover > a {
		border-color: transparent;
	}

	.header-section ul li ul.dm-align-2 {
		left: 10px;
	}

	.header-section ul li.menu-icon a:focus {
		color: #444;
	}

	.browse_pic_file input {
		width: 16rem;
	}

	.vfx-item-section h3 {
		font-size: 20px;
	}

	.apply-now-item .vfx-item-btn-danger {
		width: 250px;
	}

	.membership-plan-list h3 {
		font-size: 18px;
	}

	.membership-plan-list h4 {
		font-size: 16px;
	}

	.subscribe-form input[type="email"] {
		width: 215px;
	}

	.posts-pagination {
		margin-bottom: 20px;
	}

}

@media only screen and (min-width:768px) and (max-width:1199px) {
	.splide__slide img {
		width: 100vh !important;
		height: 100% !important;
	}

	.splide-slider-details-area h1 {
		font-size: 26px;
	}

	.splide-slider-details-area {
		top: 0;
	}

	.splide-slider-details-area .splide-slider-details-area-details {
		position: absolute;
		bottom: 25px;
		left: 25px;
	}

}

.splide-slider-details-area-details {
	position: absolute;
	bottom: 25px;
	left: 25px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
	ul.nav > li:hover > a {
		border-color: transparent;
	}

	.video-js.vjs-16-9 {
		padding-top: 46% !important;
	}

	.recently-watched-video-carousel .video-img img {
		height: 100px;
	}

	.video-counter {
		font-weight: 500;
		overflow: hidden;
		width: 90%;
	}

	.action-content h2 {
		font-size: 22px;
	}

	.video-img img {
		width: 100%;
	}

	.video-img {
		width: 100%;
	}

	.video-img a {
		display: inline-block;
		width: 100%;
	}

	.splide__arrow--prev {
		left: 1em !important;
		top: 40% !important;
	}

	.splide__arrow--next {
		right: 1em !important;
		top: 40% !important;
	}

	.vfx-item-ptb {
		padding: 20px 0;
	}

	.posts-pagination {
		margin-bottom: 20px;
	}

	.page-title h1 {
		font-size: 30px;
	}

	.video-share .share-options {
		float: right;
		padding-top: 0;
	}

	.header-section .main-nav .nav.top-nav li {
		padding-right: 20px;
	}

	.header-section ul li.menu-icon a:focus {
		color: #444;
	}

	.video-post-wrapper {
		margin-bottom: 30px;
	}

	.video-shows-carousel .single-video .video-img img {
		height: 200px;
	}

	.four-zero-page {
		padding: 100px 0;
	}

}

@media only screen and (min-width:640px) and (max-width:767px) {
	.video-shows-carousel .single-video .video-img img {
		height: 180px;
	}

	.tv-season-video-carousel .video-img img {
		height: 170px;
	}

}

@media only screen and (max-width:991px) {
	.profile-section {
		padding: 20px 15px;
	}

	.splide .splide__track::after, .splide .splide__track::before {
		display: none !important;
	}

	.member-ship-option, .profile_title_item {
		margin-bottom: 25px;
		min-height: auto;
	}

	.vfx-tabs-item ul li label {
		padding: 6px 20px;
		font-weight: 700;
		font-size: 16px;
	}

	.member-ship-option {
		padding: 20px;
	}

	.vjs-logo {
		display: none !important;
	}

	.profile_title_item .vfx-item-btn-danger, .member-ship-option .vfx-item-btn-danger {
		padding: 10px 20px
	}

}

@media only screen and (max-width:767px) {
	.text-xs-center {
		text-align: center !important;
	}

	.splide {
		padding: 0 12px;
	}

	.login-item-block {
		border-radius: 20px;
		padding: 35px 25px;
		margin-bottom: 30px;
		margin-left: 10px !important;
		margin-right: 10px !important;
	}

	.modal-content {
		margin-left: 12px;
		margin-right: 12px;
	}

	.video-js.vjs-16-9 {
		padding-top: 48% !important;
	}

	.breadcrumb-section {
		text-align: center;
		padding: 20px 10px;
	}

	#breadcrumbs {
		right: 0;
		position: relative;
		top: 0;
		transform: none;
		margin-top: 8px;
		max-width: 100%;
	}

	.subscribe-btn-item span, .signup-btn-item span {
		display: none;
	}

	.subscribe-btn-item a img, .signup-btn-item a img {
		margin-right: 0;
	}

	.subscribe-btn-item a, .signup-btn-item a {
		padding: 6px 8px;
	}

	.video-post-info h2, .poster-dtl-item h2 a {
		font-size: 22px;
		font-weight: 700;
	}

	.slider-area {
		overflow: hidden;
	}

	.splide__slide img {
		width: 100% !important;
		height: 100% !important;
	}

	.splide__track {
		width: 100% !important;
		height: auto !important;
		padding-right: 0 !important;
		overflow: inherit !important;
	}

	.splide-slider-details-area h1 {
		font-size: 22px;
		font-weight: 600;
	}

	.splide.is-active .splide__list, .splide__slide {
		width: 100%;
	}

	.splide-slider-details-area {
		top: 0;
	}

	.splide-slider-details-area .splide-slider-details-area-details {
		position: absolute;
		bottom: 30px;
		left: 20px;
	}

	.splide-slider-details-area a.btn-watch, .splide-slider-details-area a.btn-buy-plan {
		padding: 6px 8px;
		font-size: 12px;
	}

	.splide-slider-details-area a.btn-watch img, .splide-slider-details-area a.btn-buy-plan img {
		width: 16px !important;
		height: 16px !important;
		margin-right: 5px;
		vertical-align: text-bottom;
	}

	.splide .splide__track::after, .splide .splide__track::before {
		display: none !important;
	}

	.splide__arrow--prev {
		left: 1em !important;
		top: 40% !important;
	}

	.splide__arrow--next {
		right: 1em !important;
		top: 40% !important;
	}

	.social-moview-post {
		right: auto;
		bottom: 40px;
		background: rgb(0, 0, 0, 1);
		left: 110px;
	}

	.social-series-poster {
		right: auto;
		bottom: 40px;
		background: rgb(0, 0, 0, 1);
		left: 130px;
	}

	.vfx-tabs-item section {
		padding: 20px;
		margin-bottom: 0;
	}

	.member-ship-option {
		margin-bottom: 0;
		margin-top: 15px;
	}

	.edit-profile-form .form-group.flex-column {
		flex-direction: unset !important;
		margin-top: 15px !important;
	}

	.edit-profile-form .form-group label {
		width: 100%;
	}

	.user-menu .user-name {
		font-size: 0;
	}

	.user-menu .user-name i {
		font-size: 16px;
		line-height: 36px;
		padding-left: 0;
		display: none;
	}

	.user-menu .user-name::after {
		margin-left: 0;
	}

	.user-name span {
		left: -18px;
	}

	.user-menu {
		margin-left: 23px;
	}

	.right-sub-item-area {
		right: 42px !important;
	}

	.detail-poster-area .video-watch-share-item {
		float: none;
		margin-top: 10px;
	}

	.detail-poster-area .dtl-poster-img::after {
		height: 100%;
	}

	.actors-detail-wrapper {
		display: block;
	}

	.actors-detail-wrapper {
		text-align: center;
		width: 100%;
	}

	.actors-detail-wrapper .actors-info-details {
		padding: 20px 0 5px 0;
		display: inline-block;
		position: relative;
		text-align: center;
		width: 100%;
	}

	.actors-detail-wrapper .actors-profile {
		position: relative;
		max-width: 200px;
		height: 200px;
		border-radius: 100px;
		z-index: 3;
		width: 100%;
		display: inline-block;
		left: 0;
		right: 0;
	}

	.video-post-date .video-posts-author {
		margin-bottom: 5px;
		display: inline-block;
	}

	.header-section .logo {
		width: 90px;
		float: left;
		margin-right: 20px;
	}

	.hero-content .no-gutters {
		padding-bottom: 40px !important;
	}

	.contact-page-area .contact-form, .contact-info-item {
		padding: 20px;
	}

	.footer-bottom, .single-footer {
		margin-bottom: 30px
	}

	.purchase-link.text-right {
		text-align: center;
	}

	.action-content {
		text-align: center;
	}

	ul.pgwSlider > li span, .pgwSlider > .ps-list > li span {
		display: none;
	}

	.video-counter {
		width: 90%;
	}

	.vfx-item-ptb {
		padding: 20px 0;
	}

	.footer-area {
		padding: 30px 0;
		margin-top: 10px;
	}

	.see-all-link {
		display: none;
	}

	.pgwSlideshow .ps-caption {
		background: transparent none repeat scroll 0 0;
		bottom: 10px;
		left: 50px;
	}

	.ps-caption b {
		font-size: 20px;
		font-weight: 500;
	}

	ul.nav > li:hover > a {
		background-color: #f7f7f7;
		border-color: transparent;
		color: #333;
		opacity: 1;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	}

	.vfx-item-section h3 {
		font-size: 20px;
	}

	.video-img img {
		width: 100%;
	}

	.video-img {
		width: 100%;
	}

	.video-img a {
		display: inline-block;
		width: 100%;
	}

	.pgwSlideshow.narrow .ps-list .ps-next {
		z-index: 8;
	}

	.pgwSlideshow.narrow .ps-list .ps-prev {
		z-index: 8;
	}

	.header-section ul li.menu-icon {
		position: relative;
		z-index: 9999;
	}

	.page-breadcrumb {
		padding-top: 0;
		float: none;
	}

	.posts-pagination {
		margin: 0;
	}

	.page-title h1 {
		font-size: 35px;
	}

	.header-section .main-nav .nav.top-nav li {
		padding-right: 20px;
	}

	.header-section ul li.menu-icon a:focus {
		color: #444;
	}

	.video-post-wrapper {
		margin-bottom: 30px;
	}

	.four-zero-page {
		padding: 50px 0;
	}

}

@media only screen and (max-width:639px) {
	.hero-wrap h1 {
		font-size: 30px;
		font-weight: 700;
		line-height: 36px;
		margin-bottom: 15px !important;
	}

	.socail-login-item, .socail-login-item label {
		display: inline-block;
		width: 100%;
	}

	.socail-login-item .btn-facebook-item, .socail-login-item .btn-g-plus-item {
		margin-left: 0;
		margin-right: 0;
	}

	.video-shows-carousel .single-video .video-img img, .tv-season-video-carousel .video-img img {
		height: auto;
	}

	.video-img::after {
		height: 100%;
	}

	.video-carousel .single-video .video-img img {
		height: 250px;
	}

}

@media only screen and (max-width:575px) {
	.apply-coupon-code .apply-now-item .form-control {
		width: 100%;
		margin-right: 0;
		margin-bottom: 15px;
	}

	.dtl-title-block {
		display: inline-block !important;
		width: 100%;
	}

	.dtl-title-block .video-watch-share-item {
		text-align: left;
		float: none;
		position: relative;
		right: 0;
		display: block;
		margin: 7px 0 4px 5px;
	}

	.video-post-info {
		padding: 15px 20px;
	}

	.modal-dialog {
		margin: 1.75rem auto;
	}

	.apply-now-item .vfx-item-btn-danger {
		width: 100%;
		padding: 12px 20px;
	}

	.apply-coupon-code {
		padding: 25px 20px;
	}

	.apply-now-item {
		display: block;
	}

	.breadcrumb-section h2 {
		font-size: 26px;
	}

	.breadcrumb-section p {
		font-size: 16px;
	}

	.detail-poster-area .play-icon-item {
		top: 15px;
		right: 65px;
		left: auto;
	}

	.detail-poster-area .icon {
		height: 50px;
		width: 50px;
		top: 80%;
		line-height: 50px;
		font-size: 20px;
	}

	.detail-poster-area .ripple, .detail-poster-area .ripple::before, .detail-poster-area .ripple::after {
		height: 50px;
		width: 50px;
	}

}

@media only screen and (min-width:480px) and (max-width:767px) {
	.vfx-item-half {
		width: 50%;
		float: left;
		padding: 0 3%;
	}

	.see-all-link {
		display: inline-block;
	}

	.pgwSlideshow .ps-caption {
		bottom: 30%;
		left: 15%;
		background-color: transparent;
	}

	.recently-watched-video-carousel .video-img img {
		height: 120px;
	}

}

@media only screen and (max-width:469px) {
	.video-post-counter {
		display: none;
	}

	.browse_pic_file input {
		width: 100%;
		margin-right: 0;
	}

	.browse_pic_file {
		margin-right: 0;
	}

}

@media only screen and (max-width:479px) {
	.hero-wrap .lead {
		line-height: 28px;
	}

	.vfx-item-section h3 {
		font-size: 18px;
	}

	h2.form-title-item {
		font-size: 22px;
	}

	.video-js.vjs-16-9 {
		padding-top: 50% !important;
	}

	.vfx-tabs-item ul li label {
		padding: 8px 15px;
		font-size: 16px;
	}

	.vfx-tabs-item ul {
		margin-left: 5px;
	}

	.recently-watched-video-carousel .video-img img {
		height: 90px;
	}

	.video-watch-share-item {
		display: inline-block;
	}

	.server-btn-list {
		display: block;
	}

	.server-btn-list .server-btn-item {
		display: inline-block;
		float: left;
	}

	.video-watch-share-item .server-btn-item a {
		margin-right: 6px;
		margin-bottom: 5px;
	}

	.video-watch-share-item #socialGallery {
		margin-top: 10px;
	}

	.actors-member-item img {
		width: 150px;
		height: 130px;
		object-fit: cover;
	}

	.contact-page-area .contact-form, .details-page-area .details-item-block, .edit-profile-area .edit-profile-form {
		padding: 20px;
	}

	.slider-area .splide__arrows {
		display: none;
	}

	.splide--draggable > .splide__track > .splide__list > .splide__slide {
		border-radius: 6px;
	}

	.splide-slider-details-area h1 {
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 10px;
	}

	.splide-slider-details-area {
		top: 0px;
	}

	.splide-slider-details-area .splide-slider-details-area-details {
		position: absolute;
		bottom: 18px;
		left: 15px;
	}

}

@media only screen and (max-width:639px) {
	.recently-watched-video-carousel .video-img img {
		height: 150px;
	}

	.sport-video-block .video-img span.video-item-content, .live-tv-video-block .video-img span.video-item-content, .video-img span.video-item-content, .video-shows-section .video-img span.video-item-content {
		font-size: 13px;
		font-weight: 700;
		padding: 8px 12px;
	}

	.filter-list-area .page-title-item {
		padding: 6px 15px 5px 15px;
		font-size: 14px;
	}

	.custom_select_filter .nice-select {
		font-size: 14px;
		font-weight: 700;
		height: 34px;
		line-height: 35px;
	}

}

@media only screen and (max-width:479px) {
	.recently-watched-video-carousel .video-img img {
		height: auto;
	}

	.vfx-item-section span.view-more a {
		font-size: 14px;
		font-weight: 800;
	}

}

@media only screen and (max-width:355px) {
	.login-item-block a.btn-link, a.btn-link, .login-item-block a.btn-link, .custom-control-label {
		font-size: 14px;
	}

	.login-item-block .form-check-input {
		width: 16px;
		height: 16px;
		margin-top: 0.15em;
		margin-right: 6px;
	}

}

.custom_select_filter .nice-select .option.selected, .custom_select_filter .nice-select .option:hover {
	color: #ffffff;
}

@media only screen and (min-width:700px) {
	.slider-area {
		padding-top: 5rem !important;
	}

}