.Sedusainner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 1100px;
	margin: auto;
	width: 100%;
}

@media (max-width:1000px) {
	.Sedusainner {
		flex-direction: column !important;
	}

}

.Sedusaitem {
	display: flex;
	padding: 50px 5%;
	color: white;
	overflow: hidden;
}

.Sedusapane {
	width: 50%;
}

.Sedusapane:first-child {
	margin-right: 3rem;
	margin-bottom: 3rem;
}

@media (max-width:1000px) {
	.Sedusapane {
		width: 100%;
		padding: 0 45px;
		text-align: center;
	}

	.Sedusapane:first-child {
		margin-right: 0rem;
		margin-bottom: 3rem;
	}

}

.Sedusatitle {
	font-size: 50px;
	line-height: 1.1;
	margin-bottom: 8px;
}

@media (max-width:600px) {
	.Sedusatitle {
		font-size: 35px;
	}

	.Sedusainner {
		flex-direction: column !important;
	}

}

.SedusasubTitle {
	font-size: 19px;
	font-weight: 200;
	font-height: normal;
}

.Sedusaimage {
	max-width: 100%;
	height: auto;
}

.Sedusacontainer {
	min-height: 71vh !important;
	display: flex;
	justify-content: center;
}