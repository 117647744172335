.sedusa-subscription-wrapper {
	min-height: 80vh;
	background-color: #0d0620;
}

.sedusa-subscription-wrapper .sedusa-subscription-header {
	height: 160px;     /* opacity: 0.6; */
	background-image: url('../../../public/images/breadcrum-bg.jpg');
}

.sedusa-subscription-wrapper .sedusa-subscription-header .sedusa-subscription-title {

	/* justify-content: center; */
	display: flex;     /* align-items: center; */
	font-weight: 700;
	color: white;
	font-size: 33px;
	display: flex;     /* justify-content: center; */
	margin-left: 2rem;     /* margin-top: 2rem; */
	padding-top: 2rem;
}

.sedusa-subscription-wrapper .sedusa-subscription-body {
	display: flex;
	flex-wrap: wrap;
	margin-top: 15px;
	justify-content: center;
	align-items: center;
	min-height: 60vh;
}

.sedusa-subscription-wrapper .sedusa-subscription-body .sedusa-subscription-card {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 300px;
	border-radius: 5px;
	background-color: wheat;
	margin: 20px;
	background: url("../../../public/images/plan_shape_bg.png") #130a2d;
}

.sedusa-subscription-wrapper .sedusa-subscription-body .sedusa-subscription-card .sedusa-subscription-card-header {
	background: linear-gradient(90deg, #eaa538, #d31027);
	padding: 12px 10px;
	width: 100%;
	text-align: center;
	font-size: 20px;
	margin-bottom: 20px;
	color: #ffffff;
	font-weight: 700;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.sedusa-subscription-wrapper .sedusa-subscription-body .sedusa-subscription-card .sedusa-subscription-card-price {
	font-size: 38px;
	font-weight: 700;
	text-align: center;
	position: relative;
}

.sedusa-subscription-wrapper .sedusa-subscription-body .sedusa-subscription-card .sedusa-subscription-card-price::before {
	background: #d31027;
	border: 0 none;
	content: "";
	height: 4px;
	left: 0;
	position: absolute;
	bottom: -3px;
	width: 45px;
	border-radius: 4px;
	right: 0;
	margin: 0 auto;
}

.sedusa-subscription-wrapper .sedusa-subscription-body .sedusa-subscription-card .sedusa-subscription-card-price .sedusa-subscription-card-rupay {
	font-size: 27px;
	font-weight: 700;
	text-align: center;
}

.sedusa-subscription-wrapper .sedusa-subscription-body .sedusa-subscription-card .sedusa-subscription-card-duration {
	font-size: 17px;
	font-weight: 600;
	text-align: center;
	margin: 14px;
}

.sedusa-subscription-wrapper .sedusa-subscription-body .sedusa-subscription-card button {
	background: linear-gradient(90deg, #eaa538, #d31027);
	margin: 20px;
	width: 130px;
	padding: 8px;
	font-size: 15px;
	color: white;
	font-weight: 700;
	border-radius: 7px;
	transition: all 0.5s ease;
	border-style: none;
}

.sedusa-subscription-wrapper .sedusa-subscription-body .sedusa-subscription-card button:hover {
	background: linear-gradient(90deg, #d31027, #eaa538);
	margin: 20px;
	width: 130px;
	padding: 8px;
	font-size: 15px;
	color: white;
	font-weight: 700;
	border-radius: 9px;
}

.sedusa-subscription-wrapper .sedusa-subscription-body .sedusa-subscription-card {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media all and (max-width:980px) {
	.sedusa-subscription-wrapper .sedusa-subscription-body {
		flex-wrap: wrap;
	}

}