.sedusa-search-wrapper {
	display: flex;
	flex-direction: row;
	padding: 0 12%;
}

.sedusa-search-wrapper .sedusa-search-title {
}

.sedusa-search-wrapper .sedusa-search-main-div {
	height: 18rem;
	width: 14rem;
	padding: 10px 10px;
	transition: transform 0.3s ease-in-out;
}

.sedusa-search-wrapper .sedusa-search-main-div:hover {
	transform: scale(1.1);
}

.sedusa-search-wrapper .sedusa-search-main-div .sedusa-search-sub-div {
	width: 100%;
	height: 100%;
}

.sedusa-search-wrapper .sedusa-search-main-div .sedusa-search-sub-div .sedusa-search-tile {
}

.sedusa-search-wrapper .sedusa-search-main-div .sedusa-search-sub-div .sedusa-search-image {
	height: 100%;
	width: 100%;
}

@media (max-width:767px) {
	.sedusa-search-wrapper {
		display: flex;
		flex-wrap: wrap;
	}

	.sedusa-search-wrapper .sedusa-search-main-div {
		height: 10rem;
		width: 8rem;
		padding: 1rem 10px;
	}

}

@media (min-width:768px) and (max-width:1023px) {
}

@media (min-width:768px) and (max-width:1023px) and (orientation:landscape) {
}

@media (min-width:1024px) and (max-width:1279px) {
}

@media (min-width:1280px) and (max-width:1919px) {
}

@media (min-width:1920px) {
}