@charset "UTF-8";

body {
	font-family: "Nunito", sans-serif;
	font-size: 14px;
	font-weight: 500;
	background: #0d0620;
	color: #ffffff;
}

p {
	font-size: 16px;
	line-height: 1.6;
	font-weight: 600;
	color: #b5b5b5;
}

a {
	color: #ffffff;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
	text-decoration: none;
}

a:hover {
	color: #167ac6;
	text-decoration: none;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

a:focus {
	text-decoration: none;
}

img {
	max-width: 100%;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

img {
	max-width: 100%;
}

.left {
	float: left;
}

.right {
	float: right;
}

.pt-40 {
	padding-top: 40px;
}

.pb-80 {
	padding-bottom: 80px;
}

.ptb-40 {
	padding: 40px 0;
}

.no-pad-left {
	padding-left: 0 !important;
}

.no-pad-right {
	padding-right: 0 !important;
}

.no-pad-top {
	padding-top: 0 !important;
}

.no-pad-bot {
	padding-bottom: 0 !important;
}

.pb-60 {
	padding-bottom: 60px !important;
}

.pb-15 {
	padding-bottom: 15px !important;
}

.pb-60 {
	padding-bottom: 60px !important;
}

.pb-110 {
	padding-bottom: 110px !important;
}

.pb-120 {
	padding-bottom: 120px !important;
}

.pb-130 {
	padding-bottom: 130px !important;
}

.no-pad-tb {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.pt-10 {
	padding-top: 10px !important;
}

.pt-20 {
	padding-top: 20px !important;
}

.pt-30 {
	padding-top: 30px !important;
}

.pt-35 {
	padding-top: 35px !important;
}

.pt-40 {
	padding-top: 40px !important;
}

.pt-50 {
	padding-top: 50px !important;
}

.pt-60 {
	padding-top: 60px !important;
}

.pt-70 {
	padding-top: 70px !important;
}

.pt-80 {
	padding-top: 80px !important;
}

.pt-90 {
	padding-top: 90px !important;
}

.pt-100 {
	padding-top: 100px !important;
}

.pt-110 {
	padding-top: 110px !important;
}

.pt-120 {
	padding-top: 120px !important;
}

.pt-130 {
	padding-top: 130px !important;
}

.pt-140 {
	padding-top: 140px !important;
}

.pt-150 {
	padding-top: 150px !important;
}

.pb-10 {
	padding-bottom: 10px !important;
}

.pb-20 {
	padding-bottom: 20px !important;
}

.pb-30 {
	padding-bottom: 30px !important;
}

.pb-40 {
	padding-bottom: 40px !important;
}

.pb-50 {
	padding-bottom: 50px !important;
}

.pb-60 {
	padding-bottom: 60px !important;
}

.pb-70 {
	padding-bottom: 70px !important;
}

.pb-80 {
	padding-bottom: 80px !important;
}

.pb-90 {
	padding-bottom: 90px !important;
}

.pb-100 {
	padding-bottom: 100px !important;
}

.pb-110 {
	padding-bottom: 110px !important;
}

.pb-115 {
	padding-bottom: 115px !important;
}

.pb-105 {
	padding-bottom: 105px !important;
}

.py-10 {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.py-20 {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}

.py-30 {
	padding-top: 30px !important;
	padding-bottom: 30px !important;
}

.py-40 {
	padding-top: 40px !important;
	padding-bottom: 40px !important;
}

.py-50 {
	padding-top: 50px !important;
	padding-bottom: 50px !important;
}

.py-60 {
	padding-top: 60px !important;
	padding-bottom: 60px !important;
}

.py-70 {
	padding-top: 70px !important;
	padding-bottom: 70px !important;
}

.py-80 {
	padding-top: 80px !important;
	padding-bottom: 80px !important;
}

.py-90 {
	padding-top: 90px !important;
	padding-bottom: 90px !important;
}

.py-100 {
	padding-top: 100px !important;
	padding-bottom: 100px !important;
}

.py-110 {
	padding-top: 110px !important;
	padding-bottom: 110px !important;
}

.py-120 {
	padding-top: 120px !important;
	padding-bottom: 120px !important;
}

.py-130 {
	padding-top: 130px !important;
	padding-bottom: 130px !important;
}

.pl-15 {
	padding-left: 15px !important;
}

.pl-10 {
	padding-left: 10px !important;
}

.pl-8 {
	padding-left: 8px !important;
}

.pl-20 {
	padding-left: 20px !important;
}

.pl-30 {
	padding-left: 30px !important;
}

.pr-30 {
	padding-right: 30px !important;
}

.pr-20 {
	padding-right: 20px !important;
}

.pl-35 {
	padding-left: 35px !important;
}

.pr-15 {
	padding-right: 15px !important;
}

.pr-30 {
	padding-right: 30px !important;
}

.pr-45 {
	padding-right: 45px !important;
}

.mar-0 {
	margin: 0 !important;
}

.pad-lr-15 {
	padding-left: 15px !important;
	padding-right: 15px !important;
}

.pad-lr-30 {
	padding-left: 30px !important;
	padding-right: 30px !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.mr-5 {
	margin-right: 5px !important;
}

.mr-15 {
	margin-right: 15px !important;
}

.mr-30 {
	margin-right: 30px !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-5 {
	margin-top: 5px !important;
}

.mt-10 {
	margin-top: 10px !important;
}

.mt-15 {
	margin-top: 15px !important;
}

.mt-20 {
	margin-top: 20px !important;
}

.mt-25 {
	margin-top: 25px !important;
}

.mt-30 {
	margin-top: 30px !important;
}

.mt-35 {
	margin-top: 35px !important;
}

.mt-40 {
	margin-top: 40px !important;
}

.mt-45 {
	margin-top: 45px !important;
}

.mt-50 {
	margin-top: 50px !important;
}

.mt-55 {
	margin-top: 55px !important;
}

.mt-60 {
	margin-top: 60px !important;
}

.mt-65 {
	margin-top: 65px !important;
}

.mt-70 {
	margin-top: 70px !important;
}

.mt-75 {
	margin-top: 75px !important;
}

.mt-80 {
	margin-top: 80px !important;
}

.mt-85 {
	margin-top: 85px !important;
}

.mt-90 {
	margin-top: 95px !important;
}

.mt-95 {
	margin-top: 95px !important;
}

.mt-100 {
	margin-top: 100px !important;
}

.mt-105 {
	margin-top: 105px !important;
}

.mt-115 {
	margin-top: 115px !important;
}

.mt-110 {
	margin-top: 110px !important;
}

.mt-120 {
	margin-top: 120px !important;
}

.mt-125 {
	margin-top: 125px !important;
}

.mt-145 {
	margin-top: 145px !important;
}

.mt-150 {
	margin-top: 150px !important;
}

.mt-160 {
	margin-top: 160px !important;
}

.mt-170 {
	margin-top: 170px !important;
}

.mt-180 {
	margin-top: 180px !important;
}

.mt-185 {
	margin-top: 185px !important;
}

.mt-190 {
	margin-top: 190px !important;
}

.mt-195 {
	margin-top: 195px !important;
}

.mt-200 {
	margin-top: 200px !important;
}

.mt-205 {
	margin-top: 205px !important;
}

.mt-210 {
	margin-top: 210px !important;
}

.mt-90 {
	margin-top: 90px !important;
}

.mt-100 {
	margin-top: 100px !important;
}

.mt-110 {
	margin-top: 110px !important;
}

.mt-120 {
	margin-top: 120px !important;
}

.mt-130 {
	margin-top: 130px !important;
}

.mt-135 {
	margin-top: 135px !important;
}

.mt-140 {
	margin-top: 140px !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-5 {
	margin-bottom: 5px !important;
}

.mb-10 {
	margin-bottom: 10px !important;
}

.mb-15 {
	margin-bottom: 15px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-25 {
	margin-bottom: 25px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-35 {
	margin-bottom: 35px !important;
}

.mb-40 {
	margin-bottom: 40px !important;
}

.mb-45 {
	margin-bottom: 45px !important;
}

.mb-50 {
	margin-bottom: 50px !important;
}

.mb-55 {
	margin-bottom: 55px !important;
}

.mb-60 {
	margin-bottom: 60px !important;
}

.mb-65 {
	margin-bottom: 65px !important;
}

.mb-70 {
	margin-bottom: 70px !important;
}

.mb-75 {
	margin-bottom: 75px !important;
}

.mb-80 {
	margin-bottom: 80px !important;
}

.mb-85 {
	margin-bottom: 85px !important;
}

.mb-90 {
	margin-bottom: 90px !important;
}

.mb-95 {
	margin-bottom: 95px !important;
}

.mb-100 {
	margin-bottom: 100px !important;
}

.mb-110 {
	margin-bottom: 110px !important;
}

.mb-120 {
	margin-bottom: 120px !important;
}

.mb-27 {
	margin-bottom: 27px !important;
}

.mb-145 {
	margin-bottom: 145px !important;
}

.mb-150 {
	margin-bottom: 150px !important;
}

.my-10 {
	margin-top: 10px !important;
	margin-bottom: 10px !important;
}

.my-15 {
	margin-top: 15px !important;
	margin-bottom: 15px !important;
}

.my-20 {
	margin-top: 20px !important;
	margin-bottom: 20px !important;
}

.my-30 {
	margin-top: 30px !important;
	margin-bottom: 30px !important;
}

.my-40 {
	margin-top: 40px !important;
	margin-bottom: 40px !important;
}

.my-50 {
	margin-top: 50px !important;
	margin-bottom: 50px !important;
}

.my-60 {
	margin-top: 60px !important;
	margin-bottom: 60px !important;
}

.my-70 {
	margin-top: 70px !important;
	margin-bottom: 70px !important;
}

.my-80 {
	margin-top: 80px !important;
	margin-bottom: 80px !important;
}

.my-90 {
	margin-top: 90px !important;
	margin-bottom: 90px !important;
}

.my-90 {
	margin-top: 90px !important;
	margin-bottom: 90px !important;
}

.my-100 {
	margin-top: 100px !important;
	margin-bottom: 100px !important;
}

.my-110 {
	margin-top: 110px !important;
	margin-bottom: 110px !important;
}

.my-120 {
	margin-top: 120px !important;
	margin-bottom: 120px !important;
}

.my-130 {
	margin-top: 130px !important;
	margin-bottom: 130px !important;
}

.vfx-item-ptb-1 {
	padding: 20px 0;
}

.vfx-item-ptb {
	padding: 25px 0;
}

.vfx-item-ptb-2 {
	padding: 70px 0;
}

.vfx-item-ptb-3 {
	padding: 60px 0;
}

.vfx-item-ptb-4 {
	padding: 80px 0 50px;
}

.vfx-item-ptb-5 {
	padding: 30px 0 80px;
}

.bg-dark {
	background-color: #000000 !important;
}

.bg-info {
	background-color: #f7f7f7;
}

.hover-bg {
	background-color: #f44336;
}

.bg-semi-white {
	background-color: #f1f1f1;
}

.bg-slide-white {
	background-color: #f0f0f0;
}

.vfx-item-sidebar-h {
	margin-bottom: 15px;
}

.vfx-item-sidebar-h h4 {
	display: inline-block;
	font-size: 16px;
	text-transform: capitalize;
	color: #141414;
	margin: 0;
}

.vfx-item-section {
	padding: 0 0 20px 0;
}

.vfx-item-section h3 {
	display: inline-block;
	font-size: 20px;
	font-weight: 700;
	text-transform: capitalize;
	color: #ffffff;
	margin: 0;
}

.hero-wrap .hero-bg {
	background-image: url("../../../public/images/login-signup-bg-img.jpg");
}

.web-series-item-section h3 {
	display: inline-block;
	font-size: 20px;
	font-weight: 700;
	text-transform: capitalize;
	color: #ffffff;
	margin: 0;
}

.related-video-item, .tv-season-related-block {
	background: #130a2d;
	padding-top: 30px;
	padding-bottom: 0px;
}

.related-video-item:last-child, .tv-season-related-block:last-child {
	padding-bottom: 30px;
}

.related-video-item h3 {
	font-size: 22px;
}

.vfx-item-section span.view-more a {
	font-size: 15px;
	font-weight: 700;
	text-transform: capitalize;
	color: #d31027;
	float: right;
	line-height: 26px;
	padding-right: 4px;
}

.vfx-item-section span.view-more a:hover {
	color: #ffffff;
}

.vfx-item-section .see-all-link {
	float: right;
}

.vfx-item-btn-danger {
	background: linear-gradient(90deg, #eaa538, #d31027);
	border-radius: 4px;
	color: #ffffff;
	border: none;
	display: inline-block;
	font-size: 14px;
	font-weight: 700;
	padding: 8px 15px;
	text-transform: uppercase;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.vfx-item-btn-danger:hover {
	background: linear-gradient(270deg, #eaa538, #d31027);
	color: #ffffff;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.page-note-found {
	display: block;
	width: max-content;
	margin: 0 auto;
}

.btn-pd-hl {
	padding: 12px 20px;
}

.read-more-btn {
	color: #288feb;
	font-size: 16px;
	border-bottom: 1px solid #288feb;
}

body.login-signup-block {
	width: 100%;
	display: inline-grid;
}

#main-wrapper {
	background: #0d0620;
}

.hero-wrap {
	position: relative;
	width: 100%;
}

#loginForm .btn.vfx3 {
	padding: 12px 15px;
}

#loginForm .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
	background-color: #126d56;
}

#loginForm .custom-checkbox {
	margin-top: 0px;
}

.hero-wrap h1 {
	font-size: 48px;
	font-weight: 800;
	color: #fff;
	line-height: 1;
	text-transform: capitalize;
}

.hero-wrap .lead {
	display: inline-block;
	color: #f9f9f9;
	font-size: 18px;
	font-weight: 600;
	line-height: 30px;
	margin-bottom: 20px;
}

.hero-wrap .hero-mask, .hero-wrap .hero-bg, .hero-wrap .hero-bg-slideshow {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.hero-wrap .hero-bg.hero-bg-scroll {
	background-attachment: scroll;
	position: fixed;
}

.hero-wrap .hero-bg {
	z-index: 0;
	background-attachment: fixed;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	transition: background-image 300ms ease-in 200ms;
}

.hero-wrap .hero-content {
	position: relative;
	z-index: 2;
}

.hero-wrap .hero-mask {
	z-index: 1;
	position: fixed;
	background: linear-gradient(90deg, #060211, #0d0620);
	opacity: 0.88;
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.h-100 {
	height: 100% !important;
}

.hero-wrap .btn-link {
	color: #d31027 !important;
}

.socail-login-item label {
	width: 100%;
	display: flex;
}

.login-item-block a.btn-link, a.btn-link, .login-item-block a.btn-link {
	color: #ffffff;
	font-size: 15px;
	font-weight: 600;
	text-decoration: none;
}

.login-item-block a.btn-link:hover, a.btn-link:hover, .login-item-block a.btn-link:hover {
	color: #d31027;
	text-decoration: underline;
}

.login-item-block .form-control:not(.form-control-sm), .login-item-block .form-control:not(.form-control-sm) {
	padding: 0.7rem 0.96rem;
	height: inherit;
}

.login-item-block .form-control, .login-item-block .custom-select, .login-item-block .form-control, .login-item-block .custom-select {
	border-color: #dae1e3;
	font-size: 16px;
	color: #656565;
}

.login-item-block .form-control:focus, .login-item-block .custom-select:focus, .login-item-block .form-control:focus, .login-item-block .custom-select:focus {
	-webkit-box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
	box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
}

.login-item-block .form-control:focus, .login-item-block .form-control:focus {
	color: #f2f2f2;
	border-color: rgba(211, 16, 39, 0.8);
	outline: 0;
	box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

.login-item-block input {
	color: #fff;
}

.login-item-block .form-control, .login-item-block .form-control {
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5;
	color: #e4e4e4;
	background-color: #22134e;
	border: 2px solid #34226a;
	border-radius: 4px;
}

.login-item-block .form-group {
	margin-bottom: 20px;
}

.login-item-block .form-group label {
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 8px;
}

.login-item-block .form-check-input {
	width: 18px;
	height: 18px;
	margin-top: 0.15em;
	margin-right: 8px;
	border: none;
}

.login-item-block .form-check-input:checked {
	background-color: #d31027;
}

.register-welcome-item {
	position: fixed;
	height: 100%;
}

.register-item-block {
	position: absolute;
	right: 0;
}

.socail-login-item {
	display: flex;
	text-align: center;
	margin: 0 auto;
	position: relative;
	left: 0;
	right: 0;
}

.socail-login-item .btn-g-plus-item {
	background: #d10b29;
	font-weight: 600;
	font-size: 16px;
	border-radius: 6px;
	line-height: 36px;
	border: none;
	color: #ffffff;
	display: block;
	box-shadow: 0 0px 8px rgba(0, 0, 0, 0.3);
	margin: 5px 8px;
	padding: 5px 20px;
	width: 100%;
	text-align: center;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.socail-login-item .btn-g-plus-item i, .socail-login-item .btn-facebook-item i {
	font-size: 18px;
	font-weight: bold;
	vertical-align: middle;
	margin-right: 6px;
	display: inline-block;
}

.socail-login-item .btn-facebook-item {
	background: #3a559f;
	font-weight: 600;
	font-size: 16px;
	border-radius: 6px;
	line-height: 36px;
	border: none;
	color: #ffffff;
	display: block;
	box-shadow: 0 0px 8px rgba(0, 0, 0, 0.3);
	margin: 5px 8px;
	padding: 5px 22px;
	width: 100%;
	text-align: center;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.socail-login-item .btn-g-plus-item:hover {
	background: #ffffff;
	color: #d10b29;
}

.socail-login-item .btn-facebook-item:hover {
	background: #ffffff;
	color: #2d5ab5;
}

.btn-submit {
	background: linear-gradient(90deg, #eaa538, #d31027);
	border-radius: 6px;
	color: #ffffff;
	border: none;
	display: inline-block;
	font-size: 16px;
	font-weight: 700;
	padding: 13px 10px;
	width: 100%;
	text-transform: uppercase;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.btn-submit:hover {
	background: #ffffff;
	color: #d31027;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.login-item-block {
	background: rgba(13, 6, 32, 0.6);
	border-radius: 16px;
	padding: 40px;
	border: 6px solid rgba(255, 255, 255, 0.08);
	box-shadow: 0 0px 25px rgba(255, 255, 255, 0.1);
}

h2.form-title-item {
	font-size: 22px;
	font-weight: 700;
	padding-bottom: 8px;
	position: relative;
}

h2.form-title-item::before {
	background: #d31027;
	border: 0 none;
	content: "";
	height: 4px;
	left: 0;
	margin: 0;
	border-radius: 20px;
	position: absolute;
	bottom: 0px;
	width: 45px;
}

.custom-control-label, .login-item-block p {
	font-size: 15px;
	font-weight: 600;
	color: #ffffff;
}

.header-top {
	background-color: #f44336;
	overflow: hidden;
	min-height: 40px;
	line-height: 40px;
}

.header-top .user-info, .header-top .user-info a, .site-info, .site-info a {
	color: #fff;
	padding-right: 15px;
	font-weight: 400;
	text-transform: capitalize;
}

.header-top .user-info {
	padding-right: 0;
}

.header-top .user-info a:last-child {
	padding-right: 0;
}

.header-top i {
	padding-right: 10px;
	display: inline-block;
}

.header-top .mail-address a {
	text-transform: lowercase;
}

.site-info div {
	display: inline-block;
}

.user-info div {
	display: inline-block;
}

.header-top .sepator {
	padding-right: 15px;
}

.user-menu {
	position: relative;
	display: inline-block;
	cursor: pointer;
	margin-left: 30px;
}

.user-menu .user-name {
	transition: 0.2s;
	cursor: pointer;
	padding-left: 26px;
	font-size: 14px;
	font-weight: 700;
	line-height: 36px;
	text-transform: uppercase;
}

.user-name span {
	display: inline-block;
	width: 36px;
	height: 36px;
	margin-right: 10px;
	border-radius: 50%;
	position: absolute;
	left: -17px;
	top: -1px;
}

.user-menu .user-name i {
	padding-left: 5px;
}

.user-name span::after {
	position: absolute;
	content: "";
	height: 12px;
	width: 12px;
	background-color: #38b653;
	top: 1px;
	right: -3px;
	display: block;
	border: 2px solid #fff;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
	border-radius: 50%;
}

.user-name span img {
	width: 36px;
	height: 36px;
	display: inline-block;
	border-radius: 50%;
	box-sizing: content-box;
	image-rendering: -webkit-optimize-contrast;
}

.user-menu ul {
	float: left;
	text-align: left;
	position: absolute;
	top: 37px;
	right: 0;
	list-style: none;
	border: 0px solid rgba(0, 0, 0, 0.2);
	background-color: #fff;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
	display: inline-block;
	width: 160px;
	font-size: 14px;
	transform: translate3d(0, 15px, 0);
	padding: 0;
	border-radius: 4px;
	box-sizing: border-box;
	transition: 0.25s;
	visibility: hidden;
	opacity: 0;
	z-index: 110;
}

.user-menu ul::before {
	position: absolute;
	top: -12px;
	left: auto;
	bottom: auto;
	right: 0px;
	content: "";
	display: block;
	visibility: visible;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-bottom: 12px solid #fff;
	transition: all 0.3s ease;
}

.user-menu ul li {
	border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.user-menu ul li a {
	padding: 5px 10px;
	line-height: 26px;
	width: 100%;
	display: inline-block;
	color: #464646;
	text-transform: capitalize;
	transition: 0.2s;
	font-size: 14px;
	font-weight: 600;
}

.user-menu ul li a i {
	font-size: 13px;
	margin-right: 5px;
	width: 20px;
	text-align: center;
}

.table-wrapper {
	margin: 30px 0 0px 0;
	box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	overflow: hidden;
}

.fl-table {
	font-size: 18px;
	font-weight: normal;
	border: 1px solid #26184f;
	border-collapse: collapse;
	width: 100%;
	max-width: 100%;
	white-space: nowrap;
	background-color: #130a2d;
	border-radius: 6px;
	overflow: hidden;
}

.fl-table td, .fl-table th {
	text-align: left;
	padding: 15px 20px;
}

.fl-table td {
	border-right: 1px solid #26184f;
	border-bottom: 1px solid #26184f;
	font-size: 16px;
	color: #ffffff;
	font-weight: 700;
}

.fl-table td:first-child {
	width: 80px;
}

.fl-table td span.current-plan-item {
	font-size: 14px;
	font-weight: 600;
	background: linear-gradient(90deg, #eaa538, #d31027);
	padding: 2px 8px;
	margin-left: 5px;
	border-radius: 4px;
}

.fl-table td span.expires-plan-item {
	font-size: 14px;
	font-weight: 600;
	background: #28184e;
	padding: 2px 8px;
	margin-left: 5px;
	border-radius: 4px;
}

.fl-table thead th {
	color: #ffffff;
	border-right: 1px solid #26184f;
	border-bottom: 1px solid #26184f;
}

@media (max-width:1000px) {
	.header-section #menu > ul li a:hover, .header-section #menu > ul li a.active {
		color: #d31027;
	}

}

@media (max-width:991px) {
	.fl-table td, .fl-table th {
		box-sizing: border-box;
		overflow-x: hidden;
		overflow-y: auto;
		text-overflow: ellipsis;
	}

	.table-wrapper {
		overflow: hidden;
		overflow-x: scroll;
	}

}

.membership-plan-list {
	background: url("../../../public/images/plan_shape_bg.png"), #130a2d;
	border: 0px solid #24164b;
	border-radius: 10px;
	overflow: hidden;
	text-align: center;
	background-size: cover;
	margin-bottom: 25px;
}

.membership-plan-list h3 {
	background: linear-gradient(90deg, #eaa538, #d31027);
	padding: 12px 10px;
	width: 100%;
	text-align: center;
	font-size: 20px;
	margin-bottom: 20px;
	color: #ffffff;
	font-weight: 700;
}

.membership-plan-list h1 {
	font-size: 36px;
	font-weight: 800;
	position: relative;
	margin-bottom: 20px;
}

.membership-plan-list h1 span {
	font-size: 22px;
	font-weight: 800;
	padding-right: 4px;
}

.membership-plan-list h1::before {
	background: #d31027;
	border: 0 none;
	content: "";
	height: 4px;
	left: 0;
	position: absolute;
	bottom: -3px;
	width: 45px;
	border-radius: 4px;
	right: 0;
	margin: 0 auto;
}

.membership-plan-list h4 {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 20px;
}

.server-time {
	font-weight: 400;
	text-transform: capitalize;
}

.mega-menu > ul > li > ul > li > a {
	text-transform: capitalize;
}

.apply-coupon-code {
	background: "../../../public/images/plan_shape_bg.png", #130a2d;
	border: 0px solid #24164b;
	border-radius: 10px;
	overflow: hidden;
	text-align: center;
	background-size: cover;
	margin-bottom: 25px;
	padding: 30px;
}

.apply-now-item {
	display: flex;
}

.apply-coupon-code .apply-now-item .form-control {
	font-size: 1rem;
	font-weight: 700;
	line-height: 1;
	color: #e4e4e4;
	padding: 0.7rem 0.96rem;
	height: inherit;
	background-color: #201c2d;
	border: 2px solid #2c273c;
	border-radius: 4px;
	margin-right: 10px;
}

.apply-now-item .vfx-item-btn-danger {
	width: 200px;
}

.apply-coupon-code h2 {
	font-size: 22px;
	font-weight: 700;
	position: relative;
	margin-bottom: 30px;
	display: block;
}

.apply-coupon-code h2::before {
	background: #d31027;
	border: 0 none;
	content: "";
	height: 4px;
	left: 0;
	position: absolute;
	bottom: -8px;
	width: 45px;
	border-radius: 4px;
	right: 0;
	margin: 0 auto;
}

.payment-details-area {
	background: #130a2d;
	border-radius: 6px;
	border: 0px solid #26184f;
	padding: 30px;
	margin-bottom: 20px;
}

.payment-details-area h3 {
	font-size: 20px;
	font-weight: 700;
	padding-bottom: 10px;
	position: relative;
	margin-bottom: 20px;
}

.payment-details-area h3::before {
	background: #d31027;
	border: 0 none;
	content: "";
	height: 4px;
	left: 0;
	margin: 0;
	border-radius: 20px;
	position: absolute;
	bottom: 0px;
	width: 40px;
}

.payment-details-area .select-plan-text {
	display: block;
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 600;
}

.payment-details-area .select-plan-text span {
	font-size: 14px;
	color: #fff;
	line-height: 21px;
	font-weight: 600;
	display: inline-block;
	background: #28184e;
	padding: 2px 10px;
	border-radius: 4px;
	margin-left: 5px;
}

.payment-details-area p {
	display: block;
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 600;
	color: #ffffff;
}

.payment-details-area p a {
	color: #d31027;
}

.payment-details-area p a:hover {
	color: #ffffff;
	text-decoration: underline;
}

.select-payment-method {
	background: url("../../../public/images/plan_shape_bg.png"), #130a2d;
	border: 0px solid #24164b;
	border-radius: 10px;
	overflow: hidden;
	text-align: center;
	background-size: cover;
	margin-bottom: 25px;
	padding: 30px;
}

.select-payment-method h1 {
	font-size: 26px;
	font-weight: 700;
	position: relative;
	margin-bottom: 20px;
	padding-bottom: 8px;
}

.select-payment-method h1::before {
	background: #d31027;
	border: 0 none;
	content: "";
	height: 4px;
	left: 0;
	position: absolute;
	bottom: -1px;
	width: 45px;
	border-radius: 4px;
	right: 0;
	margin: 0 auto;
}

.select-payment-method h4 {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 20px;
}

.modal.stripe-payment-block {
	background: rgba(6, 8, 12, 0.6);
}

.stripe-payment-block .modal-content {
	background: #141d30;
	border-radius: 10px;
}

.stripe-payment-block .modal-header {
	padding: 1rem 1.5rem;
	border-bottom: 1px solid #25324c;
}

.stripe-payment-block .modal-body {
	padding: 1.5rem 1.5rem 2rem 1.5rem;
}

.stripe-payment-block .modal-title {
	color: #fff;
	font-size: 22px;
	font-weight: 700;
	text-transform: inherit;
}

.stripe-payment-block button.btn-close {
	background: transparent;
	color: #fff;
	opacity: 1;
	border: 2px solid #fff;
	width: 14px;
	height: 14px;
	line-height: 14px;
	border-radius: 30px;
	padding: 3px;
	font-size: 12px;
	margin-right: 0;
}

ul.nav > li.active > a {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

ul.nav > li > a {
	border-bottom: 4px solid transparent;
}

ul.nav > li a.active, ul.nav > li:hover > a {
	border-color: #d31027;
	color: #d31027;
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.mega-list li a i {
	padding-right: 0px;
}

.main-menu .vfx-item-nav > li > a {
	display: inline-block;
	padding: 23px 15px 19px 15px;
	font-size: 14px;
	font-weight: 700;
}

.modal-title {
	color: #333333;
	display: inline-block;
	font-size: 24px;
	font-weight: 700;
	text-transform: capitalize;
}

.right-sub-item-area {
	display: flex;
	padding: 16px 0;
	text-align: right;
	right: 0;
	position: absolute;
}

.search-item-block .navbar-form [type="submit"] {
	width: 36px;
	text-align: center;
	border-radius: 50px;
	line-height: 38px;
	height: 36px;
	background-color: #d31027;
	border: none;
	padding: 0;
	margin-right: 5px;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	color: #ffffff;
}

.search-item-block .navbar-form [type="submit"]:hover {
	background-color: #ffffff;
	color: #d31027;
}

.subscribe-btn-item, .signup-btn-item {
	margin: 1px 6px;
}

.subscribe-btn-item a {
	background: #eaa538;
	color: #ffffff;
	padding: 7px 9px;
	display: inline-block;
	border-radius: 4px;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 700;
}

.subscribe-btn-item a#trailer_model_button {
	font-size: 14px;
	text-transform: none;
	font-weight: 600;
	margin-left: -5px;
}

.subscribe-btn-item a:hover {
	background: #d31027;
}

.video-watch-share-item .server-btn-item a {
	background: #fe8805;
	color: #ffffff;
	padding: 6px 12px;
	display: inline-block;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 600;
	margin-right: 10px;
}

.video-watch-share-item .server-btn-item a:hover {
	background: #d31027;
}

.video-watch-share-item .server-btn-item a i {
	margin-right: 5px;
}

.subscribe-btn-item a img, .signup-btn-item a img {
	width: 18px;
	height: 18px;
	margin-right: 7px;
	vertical-align: text-top;
}

.subscribe-btn-item a img {
	margin-right: 0;
}

.signup-btn-item a {
	background: #d31027;
	color: #ffffff;
	padding: 7px 12px;
	display: inline-block;
	border-radius: 4px;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 700;
}

.signup-btn-item a:hover {
	background: #eaa538;
}

#upload-options .modal-footer {
	text-align: left;
}

#upload-options .modal-footer p {
	margin-bottom: 0;
	line-height: 24px;
}

.upload-form button, .login-form button {
	font-size: 20px;
}

.upload-form input, .upload-form textarea, .login-form input, .login-form textarea {
	border-radius: 0;
}

.upload-form input, .login-form input {
	height: 45px;
}

.modal-header .close-btn {
	float: right;
	font-size: 20px;
}

.upload-form input[type="file"] {
	display: none;
}

.custom-file-upload {
	border: 1px solid #c3e4c4;
	display: inline-block;
	padding: 35px 12px;
	cursor: pointer;
	width: 100%;
	text-align: center;
	background: #d7f5d8;
}

.navbar-toggle {
	background-color: transparent;
}

.main-nav .fa.fa-bars {
	color: #e5e5e5;
}

.ps-caption b {
	color: #fff;
	font-weight: 300;
	font-size: 24px;
	text-transform: capitalize;
}

.ps-caption b i {
	font-size: 14px;
	display: inline-block;
	padding-right: 5px;
}

.ps-caption b {
	display: block;
	margin: 0;
	font-size: 18px;
	font-weight: 700;
}

.slider-part-two .row .col-md-6:nth-child(odd) {
	padding-right: 0 !important;
	padding-left: 10px !important;
}

.slider-part-two .row .col-md-6:nth-child(even) {
	padding-left: 10px !important;
}

.slider-part-one .feature-post-img > a > img {
	height: 420px;
	object-fit: cover;
}

.slider-part-two .feature-post-img > a > img {
	height: 206px;
	object-fit: cover;
}

.heading-icon {
	background-color: #f44336;
	color: #fff;
	display: inline-block;
	width: 34px;
	height: 34px;
	line-height: 38px;
	margin-right: 10px;
	text-align: center;
	border-radius: 40px;
}

.heading-icon i {
	font-size: 16px;
}

.video-title {
	font-size: 15px;
	color: #ffffff;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
	line-height: 1.1;
	font-weight: 600;
}

.video-title:hover {
	color: #f44336;
}

.video-feedback {
	overflow: hidden;
}

.single-video .video-feedback {
	float: right;
}

.video-like-counter {
	display: inline-block;
	font-size: 12px;
	color: #767676;
	background: #ececec;
	padding: 3px 6px;
	border-radius: 4px;
}

.video-viewers {
	display: inline-block;
	font-size: 12px;
	background: #ececec;
	padding: 3px 6px;
	border-radius: 4px;
}

.view-icon {
	color: #418fd1;
	padding-right: 5px;
}

.like-icon {
	color: #359261;
	padding-right: 5px;
}

.dislike-icon {
	color: #ff0000;
	padding-right: 5px;
}

.video-img > a > img {
	max-width: 100%;
	width: 100%;
	height: 280px;
	object-fit: cover;
	-webkit-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}

.video-img > a:hover img {
	-webkit-transform: scale(1.5);
	-moz-transform: scale(1.5);
	-ms-transform: scale(1.5);
	-o-transform: scale(1.5);
	transform: scale(1.5);
	-webkit-transform: scale(1.1) rotate(0deg);
	transform: scale(1.1) rotate(0deg);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
	filter: alpha(opacity=30);
	-webkit-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}

.video-img span.video-item-content {
	background: linear-gradient(60deg, #eaa538, #d31027) !important;
	bottom: 0;
	color: #fff;
	font-size: 15px;
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	width: auto;
	padding: 10px 12px;
	line-height: 18px;
	font-weight: 600;
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: block;
	z-index: 2;
	opacity: 0;
	-webkit-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}

.video-shows-section .video-img span.video-item-content {
	background: linear-gradient(60deg, #eaa538, #d31027) !important;
	bottom: 0;
	color: #fff;
	font-size: 16px;
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	width: auto;
	padding: 10px 12px;
	line-height: 18px;
	font-weight: 700;
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: block;
	z-index: 2;
	opacity: 1;
	-webkit-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}

.video-shows-section .recently-watched-video-carousel .video-img span.video-item-content {
	background: linear-gradient(60deg, #eaa538, #d31027) !important;
	bottom: 0;
	color: #fff;
	font-size: 16px;
	position: absolute;
	left: 0;
	right: 0;
	text-align: left;
	width: auto;
	padding: 10px 12px;
	line-height: 18px;
	font-weight: 700;
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: block;
	z-index: 2;
	opacity: 0;
	-webkit-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}

.sport-video-block .video-img span.video-item-content, .live-tv-video-block .video-img span.video-item-content {
	background: linear-gradient(60deg, #eaa538, #d31027) !important;
	bottom: 0;
	color: #fff;
	font-size: 16px;
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	width: auto;
	padding: 10px 12px;
	line-height: 18px;
	font-weight: 700;
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: block;
	z-index: 2;
	opacity: 0;
	-webkit-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}

.single-video a:hover .video-item-content {
	opacity: 1;
	-webkit-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}

.video-img .owl-img-div::after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.slider-part-one .video-img img:hover {
	-webkit-transform: scale(1.5);
	-moz-transform: scale(1.5);
	-ms-transform: scale(1.5);
	-o-transform: scale(1.5);
	transform: scale(1.5);
	-webkit-transform: scale(1.3) rotate(0deg);
	transform: scale(1.3) rotate(0deg);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
	filter: alpha(opacity=30);
	-webkit-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}

.single-feature .video-img span.video-duration {
	top: 20px;
	right: 20px;
}

.vid-lab-premium {
	background: linear-gradient(60deg, #eaa538, #d31027) !important;
	border-radius: 40px;
	padding: 5px 6px;
	position: absolute;
	z-index: 2;
	right: 8px;
	top: 8px;
	width: 28px;
	height: 28px;
}

.vid-lab-premium img {
	width: 100% !important;
	height: auto !important;
	object-fit: inherit !important;
	vertical-align: text-top;
}

.single-video .season-title-item {
	padding: 10px 5px;
}

.single-video .season-title-item h3 {
	font-size: 16px;
	font-weight: 600;
	color: #ffffff;
	margin-bottom: 5px;
	width: 95%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: block;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.single-video:hover .season-title-item h3 {
	color: #d31027;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.single-video .season-title-item span {
	font-size: 14px;
	font-weight: 600;
	color: #93909b;
}

.video-img {
	display: block;
	position: relative;
	overflow: hidden;
	border-radius: 6px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.video-img img {
	width: 100%;
	object-fit: cover;
	-webkit-transition: transform 0.8s ease;
	transition: transform 0.8s ease;
}

.video-posts-video {
	position: relative;
	overflow: hidden;
	border-radius: 0;
}

.single-video .watchlist-item {
	position: absolute;
	z-index: 3;
	right: 10px;
	top: 10px;
}

.single-video .watchlist-item a {
	background: #382567;
	color: #ffffff;
	padding: 5px 7px;
	border-radius: 4px;
	font-size: 12px;
	font-weight: 600;
}

.single-video .watchlist-item a:hover {
	background: #eaa538;
}

.single-video .watchlist-item a i {
	margin-right: 5px;
	font-size: 10px;
}

.single-video a:hover .video-img img {
	transform: scale(1.12);
	-webkit-transform: scale(1.12);
	-webkit-transition: transform 0.8s ease;
	transition: transform 0.8s ease;
}

.single-video a:hover .vid-lab-premium img {
	transform: scale(1);
	-webkit-transform: scale(1);
	-webkit-transition: transform 0.8s ease;
	transition: transform 0.8s ease;
}

.video-content > h4 {
	max-height: 60px;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1;
}

.video-carousel .single-video .video-img img {

	/* max-width: 100%; */
	height: 250px;
	object-fit: fill;
	max-width: 220px;
}

.video-shows-carousel .single-video .video-img img {
	max-width: 100%;
	height: 240px;
	object-fit: cover;
}

.video-carousel .single-video .video-img .vid-lab-premium img, .video-shows-carousel .single-video .video-img .vid-lab-premium img {
	width: 30px;
	height: 30px;
}

.video-carousel-area .single-video .video-content, .video-shows-section .single-video .video-content {
	margin-top: 10px;
}

.video-carousel-area .single-video .video-content h4, .video-shows-section .single-video .video-content h4 {
	margin-bottom: 12px;
}

.view-all-video-area .single-video {
	margin-top: 10px;
	margin-bottom: 10px;
}

.live-tv-section .single-video {
	margin-top: 0;
	margin-bottom: 0;
}

.filter-list-area {
	display: flex;
	width: 100%;
	background: #130a2d;
	padding: 15px 20px;
	border-radius: 4px;
	text-align: center;
	margin-bottom: 20px;
}

.filter-list-area .page-title-item {
	background: #342e46;
	font-size: 15px;
	text-transform: uppercase;
	text-align: center;
	padding: 9px 15px 8px 15px;
	font-weight: 700;
	border-radius: 4px;
	margin: 5px 0;
	display: inline-block;
}

.show-listing .pagination {
	margin-top: 0;
}

.custom_select_filter span.current {
	text-transform: uppercase;
}

.filter-list-area .custom_select_filter {
	position: relative;
	display: inline-flex;
	margin: 5px 0 5px 10px;
	right: 0;
}

.custom_select_filter .nice-select {
	-webkit-tap-highlight-color: transparent;
	background-color: #d31027;
	border-radius: 4px;
	border: none;
	clear: both;
	cursor: pointer;
	display: block;
	float: left;
	font-size: 15px;
	font-weight: 700;
	height: 40px;
	line-height: 40px;
	outline: none;
	padding-left: 15px;
	padding-right: 30px;
	position: relative;
	text-align: left !important;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	color: #fff;
}

.custom_select_filter .nice-select::after {
	border-bottom: 3px solid #fff;
	border-right: 3px solid #fff;
	content: "";
	display: block;
	height: 8px;
	margin-top: -6px;
	width: 8px;
}

.custom_select_filter .nice-select .option {
	cursor: pointer;
	font-weight: 600;
	line-height: 34px;
	list-style: none;
	min-height: 34px;
	outline: none;
	padding-left: 15px;
	padding-right: 20px;
	text-align: left;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	color: #424242;
}

.custom_select_filter .nice-select .option:hover, .custom_select_filter .nice-select .option.focus, .custom_select_filter .nice-select .option.selected.focus {
	background-color: #d31027;
}

.custom_select_filter .nice-select .option.selected {
	color: #424242;
}

.custom_select_filter .nice-select .option:hover {
	color: #ffffff;
}

.pagination {
	padding-top: 20px;
	padding-bottom: 10px;
	margin: 0 auto;
	text-align: center;
	width: 100%;
	display: block;
}

.pagination .nav-links ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.pagination .nav-links ul li {
	display: inline-block;
}

.nav-links .page-numbers.active, .nav-links .page-numbers:hover {
	background: linear-gradient(60deg, #eaa538, #d31027) !important;
	color: #ffffff;
}

.nav-links .page-numbers.prev, .nav-links .page-numbers.next {
	line-height: 40px;
}

.nav-links .page-numbers {
	width: 40px;
	text-align: center;
	margin: 0 2px;
}

.nav-links .current-page, .nav-links .page-numbers {
	background: #ffffff;
	font-size: 16px;
	font-weight: 700;
	color: #323232;
	display: inline-block;
	height: 40px;
	line-height: 40px;
	border-radius: 30px;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.owl-dots {
	display: none;
}

.owl-nav {
	position: absolute;
	top: 50%;
	width: 100%;
	margin-top: -15px;
	right: 0;
	left: 0;
	color: #fff;
}

.owl-prev {
	background: linear-gradient(60deg, #eaa538, #d31027) !important;
	font-size: 26px;
	height: 36px;
	left: 5px;
	line-height: 26px;
	position: absolute;
	text-align: center;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
	width: 36px;
	border-radius: 30px;
}

.owl-prev:hover {
	background: #372d52 !important;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
}

.owl-next {
	background: linear-gradient(60deg, #eaa538, #d31027) !important;
	font-size: 26px;
	height: 36px;
	right: 5px;
	line-height: 26px;
	position: absolute;
	text-align: center;
	width: 36px;
	border-radius: 30px;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
}

.owl-next:hover {
	background: #372d52 !important;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
}

.owl-prev i, .owl-next i {
	font-size: 20px !important;
	line-height: 30px !important;
}

.recently-watched-video-carousel.owl-carousel .owl-nav.disabled {
	display: block;
}

.single-video .video-img a {
	position: relative;
	display: block;
}

.owl-carousel .owl-item img {
	-webkit-transform-style: initial;
	transform-style: initial;
}

.tv-season-video-carousel .video-img img {
	height: 140px;
}

.recently-watched-video-carousel .video-img img {
	height: 110px;
}

.tv-season-video-carousel .video-img .vid-lab-premium img, .recently-watched-video-carousel .vid-lab-premium img {
	min-height: auto;
}

.video-img:after {
	content: "";
	width: 100%;
	height: 80%;
	position: absolute;
	left: 0;
	bottom: 0;
	background: -webkit-linear-gradient(top, rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.8));
	background: -o-linear-gradient(bottom, rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.8));
	background: -moz-linear-gradient(bottom, rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.8));
	background: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.8));
	background: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.8));
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	visibility: hidden;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
	-webkit-transform-origin: bottom 0 0;
	transform-origin: bottom 0 0;
}

.single-video:hover .video-img:after {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	visibility: visible;
}

.see-all-link {
	color: #f44336;
	padding-left: 10px;
	line-height: 24px;
	position: relative;
	margin-left: 10px;
	font-size: 14px;
	font-weight: 400;
}

.see-all-link:hover {
	color: #f44336;
}

.video-counter {
	font-weight: 600;
}

.view-btn {
	font-weight: 500;
	color: #f44336;
	text-transform: capitalize;
	padding: 0;
	margin-right: 10px;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
}

.view-btn:hover {
	color: #000;
}

.video-counter-plan {
	overflow: hidden;
	display: inline-block;
}

.video-counter-plan .video-viewers {
	float: left;
}

.video-counter-plan .video-feedback {
	display: inline-block;
	float: left;
}

.view-shows-list-item .video-img img {
	width: 100%;
	height: 175px;
	object-fit: cover;
	-webkit-transition: transform 0.8s ease;
	transition: transform 0.8s ease;
}

.heading-link {
	color: #252525;
	font-size: 16px;
	font-weight: 600;
	text-transform: capitalize;
}

.subscribe-box {
	margin-top: 60px;
}

.subscribe-form input[type="email"] {
	width: 100%;
	height: 40px;
	border: 2px solid #b7b7b7;
	padding-left: 15px;
	font-weight: 600;
	border-radius: 20px;
	padding-right: 60px;
}

.subscribe-form {
	position: relative;
	display: block;
}

.subscribe-form button {
	position: absolute;
	right: 0;
	border: 0;
	border-radius: 0 30px 30px 0;
	background-color: #f44336;
	width: 50px;
	height: 40px;
	top: 0;
	color: #fff;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
}

.subscribe-form button:hover {
	background-color: #3694f4;
}

.big-banner a {
	display: inline-block;
	-webkit-transition: all 0.3s linear 0s;
	transition: all 0.3s linear 0s;
}

.big-banner img {
	max-width: 100%;
}

.profile-section {
	background-color: #130a2d;
	padding: 30px 20px;
	border-radius: 6px;
	border: 0px solid #26184f;
}

.img-profile img.img-rounded {
	border-radius: 80px;
	width: 150px;
	display: block;
	margin: 0 auto;
	text-align: center;
	height: auto;
}

.profile_title_item {
	color: #464646;
	text-align: center;
	margin-top: 15px;
}

.profile_title_item h5 {
	margin-bottom: 3px;
	font-size: 18px;
	color: #fff;
	font-weight: 700;
}

.profile_title_item p {
	margin-bottom: 15px;
	color: #cfcfcf;
	font-size: 16px;
	font-weight: 600;
}

.profile_title_item a, .membership_plan_dtl a {
	padding: 8px 15px;
	color: #ffffff;
	-webkit-transition: all 0.5s ease 0;
	transition: all 0.5s ease 0;
	transition: all 0.5s ease 0s;
}

.member-ship-option {
	background: url("../../../public/images/plan_shape_bg.png"), #1f153e;
	color: #fff;
	min-height: 225px;
	padding: 25px;
	border: 0px solid #26184f;
	border-radius: 10px;
	overflow: hidden;
	background-size: cover;
}

.member-ship-option h5 {
	margin-top: 0;
	margin-bottom: 25px;
	position: relative;
	padding-bottom: 5px;
	font-size: 20px;
	font-weight: 700;
}

.member-ship-option h5::before {
	background: #d31027;
	border: 0 none;
	content: "";
	height: 3px;
	left: 0;
	margin: 8px 0 10px;
	position: absolute;
	bottom: -15px;
	width: 35px;
	border-radius: 4px;
}

.profile_title_item a.vfx-item-btn-danger i, .member-ship-option a.vfx-item-btn-danger i, .fl-table td a.vfx-item-btn-danger i {
	margin-right: 7px;
}

.member-ship-option span.premuim-memplan-bold-text {
	display: block;
	margin-bottom: 10px;
}

.member-ship-option span.premuim-memplan-bold-text span {
	font-size: 14px;
	color: #fff;
	line-height: 21px;
	font-weight: 600;
	display: inline-block;
	background: rgba(255, 255, 255, 0.1);
	padding: 2px 10px;
	border-radius: 4px;
	margin-left: 5px;
}

.member-ship-option span.premuim-memplan-bold-text strong {
	font-size: 16px;
	color: #ffffff;
	font-weight: 700;
}

.call-to-action-area {
	padding: 40px 0;
	color: #fff;
}

.action-content h2 {
	font-size: 30px;
	font-weight: 400;
	margin-bottom: 10px;
	margin-top: 0;
}

.action-content p {
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 400;
}

.vfx-item-purchase-btn {
	padding: 10px 30px;
	background-color: #fff;
	color: #f26c4f;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
	text-transform: uppercase;
}

.vfx-item-purchase-btn:hover {
	background-color: #2a6f9f;
	color: #fff;
}

.vfx-item-purchase-btn-2 {
	padding: 10px 30px;
	background-color: #2a6f9f;
	color: #fff;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
	text-transform: uppercase;
}

.vfx-item-purchase-btn-2:hover {
	background-color: #71c668;
	color: #fff;
}

.vfx-item-purchase-btn-3 {
	padding: 10px 20px;
	background-color: #fff;
	color: #f44336;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
	text-transform: uppercase;
}

.vfx-item-purchase-btn-3:hover {
	background-color: #3694f4;
	color: #fff;
}

.vfx-item-purchase-btn-4 {
	padding: 10px 20px;
	background-color: #fff;
	color: #252525;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
	text-transform: uppercase;
}

.vfx-item-purchase-btn-4:hover {
	background-color: #f44336;
	color: #fff;
}

.purchase-link {
	padding-top: 10.5%;
}

.purchase-link a:focus {
	color: #71c668;
	background-color: #fff;
}

.bg-slide-white .action-content {
	color: #252525;
}

.footer-area {
	background: url("../../../public/images/footer-bg-item.png"), #170f2c;
	-webkit-background-size: contain;
	background-size: 100% auto;
	padding: 50px 0;
}

.single-footer-text p {
	line-height: 24px;
}

.footer-heading-wrap {
	margin-bottom: 10px;
}

.footer-heading {
	display: inline-block;
	font-size: 20px;
	text-transform: inherit;
	font-weight: 600;
	margin-top: 0;
	position: relative;
}

.footer-heading::before {
	background: #d31027;
	border: 0 none;
	content: "";
	height: 3px;
	left: 0;
	margin: 8px 0 10px;
	position: absolute;
	bottom: -18px;
	width: 35px;
	border-radius: 4px;
}

.social-links ul li {
	display: inline-block;
}

.single-footer .social-links {
	margin-top: 20px;
}

.social-links ul li a {
	display: block;
	width: 34px;
	height: 34px;
	line-height: 36px;
	text-align: center;
	background-color: #ffffff;
	margin-right: 5px;
	border-radius: 50%;
	font-size: 16px;
	color: #140a2c;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
}

.social-links ul li a:hover {
	background-color: #00aced;
	color: #ffffff;
}

.download-app-link-item {
	display: inline-block;
	float: left;
	width: 100%;
	margin-top: 10px;
}

.download-app-link-item .google-play-download, .download-app-link-item .apple-store-download {
	display: inline-block;
	float: left;
}

.download-app-link-item .google-play-download img, .download-app-link-item .apple-store-download img {
	border-radius: 10px;
	box-shadow: 0 3px 5px rgba(255, 255, 255, 0.15);
}

.download-app-link-item .google-play-download {
	margin-right: 10px;
}

.footer-list ul li a {
	display: inline-block;
	padding: 5px 0;
	color: #252525;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
}

.footer-list ul li a:hover {
	color: #f44336;
}

.social-links ul li a:hover {
	background-color: #d31027;
}

.scroll-top {
	position: fixed;
	width: 40px;
	height: 40px;
	background: #444444;
	bottom: 10px;
	right: 0px;
	border-radius: 30px 0 0 30px;
	z-index: 9999;
	cursor: pointer;
	-webkit-transition: all 0.6s linear 0s;
	transition: all 0.6s linear 0s;
	display: none;
}

.scroll-top .scroll-icon {
	color: #fff;
	font-size: 20px;
	line-height: 40px;
	text-align: center;
}

.scroll-top:hover {
	background: linear-gradient(60deg, #eaa538, #d31027) !important;
	-webkit-transition: all 0.6s linear 0s;
	transition: all 0.6s linear 0s;
}

.footer-bottom-area {
	text-align: center;
}

.footer-links {
	padding-bottom: 15px;
}

.footer-links ul li {
	display: inline-block;
}

.footer-links ul li a {
	padding: 0 12px;
	color: #ffffff;
	font-weight: 600;
}

.footer-links ul li a:hover {
	color: #d31027;
}

.footer-links ul li a:first-child {
	padding-left: 0;
}

.footer-logo {
	padding-bottom: 20px;
}

.copyright-text a {
	color: #2a6f9f;
}

.copyright-text p {
	margin-bottom: 0;
	font-size: 15px;
}

.breadcrumb-section {
	position: relative;
	display: flex;
	align-items: center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 0;
	background-color: #f1f1f1;
}

.breadcrumb-section {
	position: relative;
	display: flex;
	align-items: center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 0;
	background-color: #0d0620;
}

.breadcrumb-section h2 {
	display: block;
	font-size: 30px;
	line-height: 1;
	color: #ffffff;
	font-weight: 700;
	margin-bottom: 0;
	text-transform: capitalize;
	z-index: 1;
	position: relative;
	padding-left: 10px;
}

.breadcrumb-section p {
	display: block;
	font-size: 18px;
	color: #dbdbdb;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 0;
	z-index: 1;
	position: relative;
	padding-left: 10px;
}

.breadcrumb-section #breadcrumb-item {
	position: absolute;
	right: 15px;
	display: inline-block;
	font-size: 14.7px;
	top: 50%;
	transform: translateY(-51%);
	font-weight: 600;
	border-radius: 4px;
	color: #ffffff;
}

.breadcrumb-section #breadcrumb-item ul {
	margin: 0;
	padding: 0;
	line-height: 24px;
}

.breadcrumb-section #breadcrumb-item ul li:first-child {
	margin-left: 0;
}

.breadcrumb-section #breadcrumb-item ul li {
	display: inline-block;
	list-style: none;
	margin: 0 0 0 10px;
	margin-left: 10px;
	text-transform: uppercase;
}

.breadcrumb-section #breadcrumb-item ul li a {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	color: #ffffff;
	font-weight: 700;
}

.breadcrumb-section::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	background: -webkit-linear-gradient(right, rgba(56, 37, 103, 0.5), rgba(24, 17, 45, 0.9));
	background: -o-linear-gradient(left, rgba(56, 37, 103, 0.5), rgba(24, 17, 45, 0.9));
	background: -moz-linear-gradient(left, rgba(56, 37, 103, 0.5), rgba(24, 17, 45, 0.9));
	background: linear-gradient(to left, rgba(56, 37, 103, 0.5), rgba(24, 17, 45, 0.9));
	background: linear-gradient(to left, rgba(56, 37, 103, 0.5), rgba(24, 17, 45, 0.9));
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
	-webkit-transform-origin: left 0 0;
	transform-origin: left 0 0;
	z-index: 1;
}

.bg-xs {
	background-position: center;
	background-size: cover;
	height: 160px;
}

.breadcrumb-section #breadcrumb-item ul li::after {
	content: "";
	display: inline-block;
	height: 0;
	width: 0;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-left: 5px solid #f9f9f9;
	font-size: 14px;
	margin-left: 15px;
}

#breadcrumbs {
	position: absolute;
	right: 15px;
	display: inline-block;
	font-size: 14.7px;
	top: 50%;
	transform: translateY(-51%);
	font-weight: 600;
	border-radius: 4px;
	color: #ffffff;
	z-index: 9;
}

#breadcrumbs ul {
	margin: 0;
	padding: 0;
	line-height: 24px;
}

#breadcrumbs ul li:first-child {
	margin-left: 0;
}

#breadcrumbs ul li {
	display: inline-block;
	list-style: none;
	margin: 0 0 0 7px;
	margin-left: 7px;
}

#breadcrumbs ul li:first-child::before {
	display: none;
}

#breadcrumbs ul li::before {
	content: "";
	display: inline-block;
	height: 0;
	width: 0;
	border-top: 4px solid transparent;
	border-bottom: 5px solid transparent;
	border-left: 5px solid #f9f9f9;
	font-size: 14px;
	margin-right: 12px;
}

#breadcrumbs ul li a {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	color: #ffffff;
	font-weight: 600;
}

#breadcrumbs ul li a:hover {
	color: #d31027;
}

.video-post-wrapper .posts-image {
	padding-bottom: 50px;
}

.video-post-wrapper .posts-image img {
	max-width: 100%;
}

.video-posts-data {
	overflow: hidden;
	margin: 20px 0;
}

.video-posts-data .video-title {
	float: left;
}

.video-watch-share-item {
	display: flex;
	margin-top: 15px;
}

.video-imdb-view {
	background: #4a2c9f;
	color: #ffffff;
	padding: 3px 5px;
	display: inline-block;
	border-radius: 4px;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 700;
}

.video-imdb-view img {
	max-width: 32px;
	height: auto;
	margin-right: 7px;
	vertical-align: text-top;
}

.social-moview-post {
	position: absolute;
	right: 10px;
	bottom: 38px;
	background: rgba(0, 0, 0, 0.5);
	padding: 8px;
	min-width: max-content;
	border-radius: 40px;
}

.social-moview-tv-detail {
	right: 0px;
	bottom: 5px;
	background: rgba(0, 0, 0, 0.5);
	padding: 5px;
	border-radius: 40px;
	position: relative;
	margin-bottom: -10px;
	max-width: max-content;
}

.social-series-poster {
	position: absolute;
	right: 5px;
	bottom: 38px;
	background: rgba(0, 0, 0, 0.5);
	padding: 8px;
	border-radius: 40px;
}

#socialGallery {
	display: none;
}

#socialGallery .socialToolBox a {
	width: 32px;
	height: 32px;
	display: inline-block;
	text-align: center;
	line-height: 34px;
	font-size: 16px;
	border-radius: 50px;
	margin: 0 1px;
	color: #ffff;
}

#socialGallery .socialToolBox a.facebook {
	background: #4267b2;
}

#socialGallery .socialToolBox a.facebook:hover {
	background: #ffffff;
	color: #4267b2;
}

#socialGallery .socialToolBox a.instagram {
	background: #fe0278;
}

#socialGallery .socialToolBox a.instagram:hover {
	background: #ffffff;
	color: #fe0278;
}

#socialGallery .socialToolBox a.twitter {
	background: #1da1f2;
}

#socialGallery .socialToolBox a.twitter:hover {
	background: #ffffff;
	color: #1da1f2;
}

#socialGallery .socialToolBox a.linkedin {
	background: #0077b5;
}

#socialGallery .socialToolBox a.linkedin:hover {
	background: #ffffff;
	color: #0077b5;
}

#socialGallery .socialToolBox a.whatsapp {
	background: #25cb63;
}

#socialGallery .socialToolBox a.whatsapp:hover {
	background: #ffffff;
	color: #25cb63;
}

#socialGallery .socialToolBox a.google {
	background: #ea4335;
}

#socialGallery .socialToolBox a.google:hover {
	background: #ffffff;
	color: #ea4335;
}

.btn-watchlist a {
	background: #118d04;
	color: #ffffff;
	padding: 6px 12px;
	display: inline-block;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 600;
	margin-right: 10px;
}

.btn-watchlist a:hover {
	background: #ffffff;
	color: #118d04;
}

.btn-watchlist a i {
	width: 16px;
	height: 16px;
	margin-right: 5px;
	vertical-align: inherit;
}

.btn-share a {
	background: #0a789c;
	color: #ffffff;
	padding: 6px 8px;
	display: inline-block;
	border-radius: 4px;
	font-size: 14px;
	margin-right: 8px;
	font-weight: 600;
}

.btn-share a:hover {
	background: #ffffff;
	color: #0a789c;
}

.btn-share a:hover, .btn-share a:focus {
	background: #0a789c;
	color: #ffffff;
}

.btn-share a i {
	width: 16px;
	height: 16px;
	vertical-align: inherit;
}

.single-feature .video-title {
	position: absolute;
	left: 20px;
	right: 20px;
	color: #fff;
	bottom: 20px;
	max-height: 45px;
	line-height: 1.5em;
	overflow: hidden;
}

.single-feature .single-video .video-img a:before {
	content: none;
}

.single-feature .single-video:hover .video-img a:after {
	content: none;
}

.single-feature .single-video {
	position: relative;
}

.detail-poster-area .play-icon-item {
	right: 0;
	left: 0;
	position: absolute;
	top: 40%;
	bottom: auto;
}

.detail-poster-area .icon {
	position: absolute;
	left: 0px;
	right: 0px;
	top: 50%;
	margin-top: -40px;
	height: 80px;
	width: 80px;
	line-height: 80px;
	background-color: #fff;
	color: #d31027;
	z-index: 1;
	font-size: 30px;
	text-align: center;
	display: block;
	margin: 0 auto;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	-webkit-transform-origin: center;
	transform-origin: center;
	box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
}

.detail-poster-area i.icon {
	margin-top: -40px !important;
}

.detail-poster-area .ripple, .detail-poster-area .ripple:before, .detail-poster-area .ripple:after {
	position: absolute;
	right: 0px;
	bottom: 0px;
	height: 80px;
	width: 80px;
	margin: 0 auto;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.35);
	-moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.35);
	-ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.35);
	-o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.35);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.35);
	-webkit-animation: ripple 3s infinite;
	-moz-animation: ripple 3s infinite;
	-ms-animation: ripple 3s infinite;
	-o-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
}

.detail-poster-area .ripple:before {
	-webkit-animation-delay: 0.9s;
	-moz-animation-delay: 0.9s;
	-ms-animation-delay: 0.9s;
	-o-animation-delay: 0.9s;
	animation-delay: 0.9s;
	content: "";
	position: absolute;
	right: 0;
	bottom: 0;
	top: 0;
}

.detail-poster-area .ripple:after {
	-webkit-animation-delay: 0.6s;
	-moz-animation-delay: 0.6s;
	-ms-animation-delay: 0.6s;
	-o-animation-delay: 0.6s;
	animation-delay: 0.6s;
	content: "";
	position: absolute;
	right: 0;
	bottom: 0;
	top: 0;
}

@-webkit-keyframes ripple {
	70% {
		box-shadow: 0 0 0 70px rgba(244, 68, 56, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(244, 68, 56, 0);
	}

}

@keyframes ripple {
	70% {
		box-shadow: 0 0 0 70px rgba(244, 68, 56, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(244, 68, 56, 0);
	}

}

.detail-poster-area {
	position: relative;
	overflow: hidden;
	border-radius: 10px;
}

.detail-poster-area .video-post-date {
	position: absolute;
	left: 0;
	bottom: 0;
	z-index: 9;
	width: 100%;
	padding: 15px;
}

.detail-poster-area .video-watch-share-item {
	display: block;
	position: relative;
	float: right;
	margin-top: 0;
}

.dtl-poster-img {
	position: relative;
	min-height: 300px;
	min-width: 200px;
}

.dtl-poster-img img {
	width: 100%;
	object-fit: cover;
}

.detail-poster-area .dtl-poster-img::after {
	content: "";
	width: 100%;
	height: 80%;
	position: absolute;
	left: 0;
	bottom: 0;
	background: -webkit-linear-gradient(top, rgba(255, 0, 0, 0), rgba(10, 10, 10, 1));
	background: -o-linear-gradient(bottom, rgba(255, 0, 0, 0), rgba(10, 10, 10, 1));
	background: -moz-linear-gradient(bottom, rgba(255, 0, 0, 0), rgba(10, 10, 10, 1));
	background: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(10, 10, 10, 1));
	background: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(10, 10, 10, 1));
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	visibility: visible;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
	-webkit-transform-origin: bottom 0 0;
	transform-origin: bottom 0 0;
}

.video-posts-data .video-post-counter {
	float: right;
}

.video-icons {
	width: 50px;
	height: 50px;
	display: inline-block;
	background-color: #f44336;
	line-height: 52px;
	color: #fff;
	text-align: center;
	font-size: 30px;
}

.video-post-title p {
	margin-bottom: 0;
}

.video-post-title .video-icons {
	float: left;
}

.video-post-title .video-post-info {
	float: left;
}

.video-post-date p, .video-post-author p {
	display: inline-block;
	font-size: 12px;
}

.video-post-date .video-posts-author {
	padding-right: 10px;
	font-size: 14px;
	font-weight: 600;
}

.video-post-info ul.actor-video-link {
	display: inline-block;
	list-style-type: none;
	margin: 10px 0 0 0;
}

.video-post-info ul.actor-video-link li {
	display: inline-block;
	list-style-type: none;
	padding: 0 7px;
	line-height: 1;
	border-left: 2px solid rgba(255, 255, 255, 0.6);
}

.video-post-info ul.actor-video-link li:first-child {
	padding-left: 0;
	border-left: 0;
}

.video-post-info ul.actor-video-link a {
	font-size: 16px;
	font-weight: 600;
	color: #ffffff;
}

.video-post-info ul.actor-video-link a:hover {
	color: #d31027;
}

.video-post-date .video-posts-author i {
	margin-right: 6px;
}

.video-post-info h4 {
	font-size: 16px;
	font-weight: 500;
	margin-top: 5px;
	margin-bottom: 5px;
}

.video-post-info h4 a {
	color: #444;
	font-weight: 700;
}

.video-post-info h4 a:hover {
	color: #f44336;
}

.video-post-info {
	width: 100%;
	background: #190d39;
	padding: 20px 25px;
	border-radius: 4px;
}

.video-post-info h2 {
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 12px;
}

.poster-dtl-item h2 a {
	font-size: 26px;
	font-weight: 700;
	color: #ffffff;
}

.poster-dtl-item h2 a:hover {
	color: #d31027;
}

.video-post-viewers h3 {
	font-size: 22px;
	font-weight: 400;
	margin: 0;
}

.video-like p, .video-dislike p {
	margin-bottom: 0;
	display: inline-block;
}

.video-post-counter .video-like {
	float: left;
}

.video-post-counter .video-dislike {
	float: right;
}

.video-like span {
	color: #359261;
}

.video-dislike span {
	color: #ff0000;
}

.video-posts-author a {
	padding-left: 10px;
}

.actors-detail-wrapper {
	background: #130a2d;
	border-radius: 6px;
	border: 0px solid #26184f;
	width: 100%;
	overflow: hidden;
	padding: 25px;
	margin-top: 10px;
	margin-bottom: 30px;
	display: inline-block;
	position: relative;
	box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

.actors-detail-wrapper::after {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	background: -webkit-linear-gradient(bottom, rgba(6, 2, 17, 0), rgba(13, 6, 32, 5));
	background: -o-linear-gradient(bottom, rgba(6, 2, 17, 0), rgba(13, 6, 32, 5));
	background: -moz-linear-gradient(bottom, rgba(6, 2, 17, 0), rgba(13, 6, 32, 5));
	background: linear-gradient(to bottom, rgba(6, 2, 17, 0), rgba(13, 6, 32, 5));
	background: linear-gradient(to bottom, rgba(6, 2, 17, 0), rgba(13, 6, 32, 5));
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
	-webkit-transform-origin: left 0 0;
	transform-origin: left 0 0;
	z-index: 1;
}

.actors-detail-wrapper .actors-profile {
	position: relative;
	z-index: 3;
}

.actors-detail-wrapper .actors-profile img {
	max-width: 170px;
	height: 170px;
	border-radius: 100px;
	border: 5px solid #331f6f;
	display: block;
	object-fit: cover;
	margin: 0 auto 15px auto;
}

.actors-detail-wrapper .actors-info-details {
	display: inline-block;
	text-align: center;
	width: 100%;
}

.actors-detail-wrapper .actors-info-details h4 {
	font-size: 24px;
	font-weight: 700;
	color: #ffffff;
	margin-bottom: 0;
}

.actors-info-details span.des-bold-text {
	font-size: 16px;
	color: #c2c0c0;
	line-height: 28px;
	font-weight: 600;
	display: block;
	margin-bottom: 5px;
}

.actors-info-details span.des-bold-text:last-child {
	margin-bottom: 0;
}

.actors-info-details span.des-bold-text strong {
	font-size: 17px;
	color: #ffffff;
	font-weight: 700;
}

.actors-info-details span.des-bold-text a {
	font-size: 16px;
	color: #c2c0c0;
	font-weight: 600;
}

.actors-info-details span.des-bold-text a:hover {
	color: #d31027;
}

.share-options h4 {
	margin: 0;
	display: inline-block;
	text-transform: uppercase;
	font-weight: 300;
	color: #f44336;
}

.posts-pagination {
	margin: 50px 0;
}

.contact-form .form-control, .edit-profile-form .form-control, .stripe-payment-form .form-control {
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.5;
	color: #e4e4e4;
	padding: 0.7rem 0.96rem;
	height: inherit;
	background-color: #201c2d;
	border: 2px solid #2c273c;
	border-radius: 4px;
	width: 100%;
}

.contact-form .form-group label, .edit-profile-form .form-group label, .stripe-payment-form .form-group label {
	font-size: 16px;
	color: #ffffff;
	font-weight: 700;
	margin-bottom: 5px;
	display: block;
}

textarea.form-control {
	min-height: 100px;
}

.contact-us-wrapper h4 {
	color: #323232;
	font-size: 20px;
	line-height: 28px;
	margin: 0;
}

.contact-us-wrapper > p {
	color: #777777;
	font-size: 15px;
	line-height: 26px;
	margin: 25px 0;
}

#respond .vfx-item-btn-danger {
	margin-top: 20px;
}

.contact-info-item {
	background: #130a2d;
	border-radius: 6px;
	border: 0px solid #26184f;
	padding: 30px 30px 20px 30px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.contact-info-item .address-info-item {
	font-size: 24px;
	font-weight: 700;
	padding-bottom: 8px;
	position: relative;
}

.contact-info-item h3.address-info-item::before {
	background: #d31027;
	border: 0 none;
	content: "";
	height: 4px;
	left: 0;
	margin: 0;
	border-radius: 20px;
	position: absolute;
	bottom: 0px;
	width: 45px;
}

.contact-info-item .utf-contact-section address, .contact-info-item .utf-contact-section ul {
	margin: 0;
}

.contact-info-item ul.list-info li .align-items-center {
	-ms-flex-align: center !important;
	align-items: center !important;
	background-color: #201c2d;
	border: 1px solid #2c273c;
	margin-bottom: 15px;
	padding: 10px 20px;
	border-radius: 4px;
	display: flex;
	box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

.contact-info-item .list-info .info-icon {
	font-size: 28px;
	margin-right: 66px;
	position: relative;
}

.contact-info-item .list-info .info-details {
	padding: 6px 0 6px 20px;
	display: inline-block;
}

.contact-info-item .list-info .info-icon i {
	background: #181328;
	-webkit-transform: translate(0, -50%);
	left: 0;
	line-height: 100%;
	position: absolute;
	top: 50%;
	color: #ffffff;
	transform: translate(0, -50%);
	width: 66px;
	height: 66px;
	line-height: 60px;
	border: 2px solid #2a2340;
	text-align: center;
	border-radius: 50px;
}

.contact-info-item .list-info .info-details .info-title {
	font-size: 20px;
	font-weight: 700;
	margin-top: 0px;
	margin-bottom: 8px;
	color: #e4e4e4;
}

.contact-info-item .list-info .info-details .info-value {
	font-size: 16px;
	font-weight: 600;
	color: #b5b5b5;
}

.contact-info-item .list-info .info-details .info-value a:hover {
	color: #d31027;
}

.contact-page-area .contact-form, .details-page-area .details-item-block, .edit-profile-area .edit-profile-form {
	background: #130a2d;
	border-radius: 6px;
	border: 0px solid #26184f;
	padding: 30px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.details-page-area {
	min-height: 77vh;
}

.details-page-area .details-item-block p {
	font-size: 16px;
	line-height: 25px;
	font-weight: 600;
	color: #c2c0c0;
	margin-bottom: 15px;
}

.contact-page-area .vfx-item-section {
	padding-top: 0;
}

.contact-page-area .vfx-item-section p {
	margin-top: 10px;
}

.four-zero-page {
	padding: 200px 0;
}

header .modal-content {
	border-radius: 0;
}

header .modal-header {
	background-color: #f44336;
	padding: 0;
}

header .modal-header h3 {
	color: #fff;
	font-size: 20px;
	font-weight: 400;
	padding: 10px 15px;
	text-transform: capitalize;
	height: 50px;
	line-height: 29px;
	border: none !important;
}

header .modal-header .close-btn {
	background-color: #d93d3d;
	border: medium none;
	border-radius: 0;
	color: #fff;
	font-size: 33px;
	height: 50px;
	line-height: 33px;
	margin: 0;
	width: 40px;
}

header .modal-body .btn.btn-primary {
	background-color: #f44336;
	border: medium none;
	border-radius: 4px;
	font-size: 18px;
	min-width: 116px;
}

.modal-content {
	border: none;
}

.popup-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.97);
	transition: opacity 500ms;
	visibility: hidden;
	opacity: 0;
}

.popup-overlay:target {
	visibility: visible;
	opacity: 1;
}

.search .close {
	position: absolute;
	top: 20px;
	right: 30px;
	transition: all 200ms;
	font-size: 20px;
	text-decoration: none;
}

.search .close:hover {
	color: #06d85f;
}

.search .section-padding {
	padding: 15px 0 10px;
	background: rgba(255, 255, 255, 0.05);
}

.popup-view.hide {
	display: none;
}

.popup-view {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100vh);
	z-index: 100000;
	background: rgba(13, 6, 32, 0.96);
	color: #fff;
	overflow-y: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	transform: translate3d(0px, 0px, 0px);
	transition: all 0.5s ease-in;
}

.search-results #search_output {
	text-align: center;
	margin: 20px auto;
	font-size: 22px;
	font-weight: 500;
}

.popup-view .centered {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	width: 100%;
	max-width: 800px;
}

.popup-view .centered02 {
	position: absolute;
	top: 20%;
	left: 50%;
	transform: translate(-50%, 0);
	text-align: center;
	width: 100%;
	max-width: 800px;
}

.popup-view .error-msg {
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.popup-view .centered.opaque {
	background: #333;
}

.popup-view .centered.padded {
	padding: 20px;
}

@media (max-width:700px) {
	.popup-view .centered.padded {
		padding-top: 35px;
		background: #333;
		border-radius: 4px;
	}

}

.popup-view .close {
	position: absolute;
	top: 10px;
	right: 20px;
	font-size: 24px;
	color: #ffffff;
	cursor: pointer;
	z-index: 20000;
	transform: scale(1);
	transition: opacity 0.1s, transform 0.1s;
	opacity: 0.9;
}

.popup-view .close i {
	display: block;
}

.popup-view .close:hover {
	opacity: 0.9;
	transform: scale(1.1);
	transition: opacity 0.1s, transform 0.1s;
}

.popup-view .close02 {
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 22px;
	width: 40px;
	height: 40px;
	text-align: center;
	cursor: pointer;
	transform: scale(1);
	transition: opacity 0.1s, transform 0.1s;
	opacity: 0.9;
	display: flex;
	justify-content: center;
	background: rgba(0, 0, 0, 0.4);
	border-radius: 50%;
	align-items: center;
}

.popup-view .close02:hover {
	opacity: 0.9;
	transform: scale(1.2);
	transition: opacity 0.1s, transform 0.1s;
}

.popup-view .close02 i {
	display: block;
}

@media (min-width:768px) {
	.popup-view .entitlement-overlay-wrapper {
		position: static;
		margin-top: 0px;
	}

}

@media (max-width:700px) {
	.popup-view .close {
		right: 20px;
		top: 10px;
	}

	.popup-view .close02 {
		background: rgba(0, 0, 0, 0.5);
		font-size: 30px;
		width: 30px;
		height: 30px;
		top: 5px;
		right: 5px;
	}

}

@media (min-width:600px) {
	.popup-view .centered {
		margin-top: 25px;
	}

}

@media (min-width:1025px) {
	.popup-view .auth-view.centered {
		margin-top: -67px;
	}

}

.popup-view .button {
	padding: 10px 10px;
	min-width: 130px;
	text-transform: uppercase;
	font-size: 0.8em;
	display: inline-block;
	outline: none;
	text-align: center;
	cursor: pointer;
	-webkit-appearance: none;
	border: 1px solid rgba(0, 0, 0, 0);
	outline: none;
	color: inherit;
}

.popup-view .button.left-margin {
	margin-left: 20px;
}

.popup-view .button.fat {
	padding: 10px 20px;
}

.popup-view .button.disabled {
	pointer-events: none;
	opacity: 0.8;
}

.popup-view button:disabled {
	pointer-events: none;
	opacity: 0.8;
}

.popup-view .button.outlined {
	border: 1px solid #666;
}

.popup-view .button.inactive {
	border: 1px solid #666;
	background: #222;
}

.popup-view .button.processing {
	opacity: 0.7;
	pointer-events: none;
}

.popup-view .mobile-logo-image {
	margin: 15px auto 10px auto;
	max-width: 150px;
	display: none;
}

@media (max-width:700px) {
	.popup-view .centered {
		height: 100%;
		overflow-y: auto;
	}

	.popup-view .mobile-logo-image {
		display: block;
	}

}

.popup-view .centered h2 {
	margin-bottom: 3px;
}

.popup-view input[type="text"], .popup-view input[type="email"], .popup-view input[type="password"] {
	width: 100%;
	padding: 7px;
	margin: 10px 0;
}

.popup-view .error-box {
	margin: 5px;
	font-size: 0.8em;
	color: red;
}

.popup-view .error {
	color: red;
	font-size: 0.9em;
}

@keyframes reveal {
	0% {
		opacity: 0;
		transform: translateY(100%);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}

}

.search-results .video-tray {
	display: none;
}

.search-results .video-tray .slick-track {
	margin-left: 0;
	margin-right: 0;
}

.search-results .BlockTitle {
	color: #fff !important;
}

.search {
	position: relative;
	top: 2%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0;
	text-align: left;
}

.search .logo {
	display: flex;
	justify-content: center;
	height: 45px;
}

.search-container {
	display: flex;
	flex-direction: column;
}

.search .search-container > .title {
	margin-bottom: 5px;
	color: #d9d9d9;
	letter-spacing: 0;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
}

.search .search-input {
	display: flex;
	margin-top: 5px;
	margin-bottom: 0px;
}

.search .search-input input[type="text"] {
	width: 100%;
	max-width: 100%;
	padding: 0 15px;
	outline: none;
	margin: 0;
	color: #e5e5e7;
	background-color: #201c2d;
	border: 2px solid #2c273c;
	border-radius: 6px;
	box-shadow: none;
	font-size: 16px;
	font-weight: 600;
	height: 46px;
}

::placeholder .search .search-input input[type="text"]::placeholder, .search .search-input input[type="text"].focus {
	background: #201c2d !important;
}

.search .no-results {
	text-align: center;
	padding-top: 15px;
	font-weight: 700;
}

.search-results {
	display: block;
	position: relative;
}

.search-results.search-results-mobile.results {
	display: block;
	background: #ddd;
	height: 400px;
	overflow: scroll;
}

.search-results.search-results-mobile .search-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px;
	border-bottom: 1px solid grey;
	color: #000;
	position: relative;
}

.search-results.search-results-mobile .search-item .content-type {
	position: absolute;
	font-size: 0.5em;
	top: 5px;
	right: 15px;
}

.search-results.search-results-mobile .search-item.video-item .title {
	font-size: 15px;
}

.search-results.search-results-mobile .search-item.video-item .title {
	flex-basis: 55%;
}

.search-results.search-results-mobile .search-item .duration {
	text-align: right;
	font-size: 13px;
	color: grey;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.75px;
}

.search-results.search-results-mobile .search-item.video-item .duration {
	flex-basis: 45%;
}

@media (min-width:1025px) {
	.search .section-padding {
		padding: 20px 0;
	}

	.search .vfx_section_header {
		margin-bottom: 30px;
	}

	.search .video-carousel .owl-buttons {
		position: absolute;
		top: -59px;
	}

	.search {
		top: 12%;
		opacity: 0;
		animation: reveal 1s cubic-bezier(0, 1, 0.5, 1) 1 normal forwards;
		animation-delay: 0.5s;
	}

	.search-container {
		padding: 0 12%;
	}

	.search .search-input {
		margin-top: 0px;
	}

	.search .logo {
		display: none;
	}

	.search-results .video-tray {
		display: block;
	}

	.search .search-container > .title {
		display: block;
	}

	.search-container.has-results {
		margin-top: 0px;
	}

	.search-results.search-results-mobile.results {
		display: none;
	}

}

@media (max-width:1024px) {
	.search-container {
		padding: 0 15px;
	}

	.popup-view .section-padding {
		padding-left: 5px;
		padding-right: 5px;
	}

}

.vfx-tabs-item {
	margin-top: 20px;
	display: inline-block;
	width: 100%;
}

.vfx-tabs-item > input, .vfx-tabs-item section > div {
	display: none;
}

.tabs_item_block .video-carousel .owl-buttons, .tabs_item_block .news-carousel .owl-buttons {
	position: absolute;
	top: 0;
}

#tab1:checked ~ section .tab1, #tab2:checked ~ section .tab2, #tab3:checked ~ section .tab3 {
	display: block;
}

#tab1:checked ~ nav .tab1, #tab2:checked ~ nav .tab2, #tab3:checked ~ nav .tab3 {
	color: #d31027;
}

.vfx-tabs-item ul {
	list-style: none;
	margin: 0;
	padding: 0;
	margin-left: 15px;
}

.vfx-tabs-item ul li label {
	float: left;
	padding: 10px 25px;
	border-bottom: 0;
	background: #26184f;
	color: #ffffff;
	margin-right: 5px;
	margin-bottom: 0;
	border-radius: 6px 6px 0 0;
	font-weight: 700;
	font-size: 18px;
}

.vfx-tabs-item ul li label:hover, .vfx-tabs-item ul li label:active {
	background: linear-gradient(60deg, #eaa538, #d31027);
	cursor: pointer;
}

.vfx-tabs-item section {
	clear: both;
	border: 1px solid #26184f;
	background: #130a2d;
	padding: 20px 25px;
	margin-bottom: 30px;
	border-radius: 6px;
}

.vfx-tabs-item section div h2 {
	margin: 0;
	letter-spacing: 1px;
	color: #34495e;
}

.poster-dtl-item ul.dtl-list-link {
	list-style-type: none;
	padding: 0;
	margin-top: 10px;
	margin-bottom: 10px;
}

.poster-dtl-item ul.dtl-list-link li {
	display: inline-block;
	border-right: 2px solid rgba(255, 255, 255, 0.5);
	padding: 0 7px;
	line-height: 1;
}

.poster-dtl-item ul.dtl-list-link li:first-child {
	padding-left: 0;
}

.poster-dtl-item ul.dtl-list-link li:last-child {
	border-right: none;
}

.poster-dtl-item ul.dtl-list-link li a {
	color: #ffffff;
	font-size: 16px;
	font-weight: 700;
}

.poster-dtl-item ul.dtl-list-link.dtl-link-col li a {
	color: #b5b5b5;
}

.poster-dtl-item ul.dtl-list-link li a:hover {
	color: #d31027;
}

.poster-dtl-item ul li span.channel_info_count {
	background: #d31027;
	min-width: max-content;
	display: inline-block;
	line-height: 22px;
	padding: 0px 6px;
	text-align: center;
	border-radius: 4px;
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
	font-weight: 700;
	font-size: 14px;
	color: #fff;
}

.description-detail-item span.des-bold-text, .poster-dtl-item span.des-bold-text {
	font-size: 16px;
	color: #c2c0c0;
	line-height: 28px;
	font-weight: 600;
	display: block;
	margin-bottom: 5px;
}

.description-detail-item span.des-bold-text a, .poster-dtl-item span.des-bold-text a {
	font-size: 16px;
	color: #c2c0c0;
	font-weight: 600;
}

.description-detail-item span.des-bold-text a:hover, .poster-dtl-item span.des-bold-text a:hover {
	color: #d31027;
}

.description-detail-item span.des-bold-text strong, .poster-dtl-item span.des-bold-text strong {
	font-size: 17px;
	color: #ffffff;
	font-weight: 700;
}

.description-detail-item h3, .poster-dtl-item h3 {
	font-size: 16px;
	line-height: 28px;
	margin: 5px 0 10px 0;
	color: #ffffff;
	font-weight: 600;
}

.description-detail-item p, .poster-dtl-item p {
	font-size: 15px;
	line-height: 26px;
	font-size: 16px;
	line-height: 26px;
	font-weight: 600;
	color: #c2c0c0;
	margin-top: 8px;
}

#tab1:checked ~ nav .tab1 label, #tab2:checked ~ nav .tab2 label, #tab3:checked ~ nav .tab3 label {
	background: linear-gradient(60deg, #eaa538, #d31027);
	color: #ffffff;
	position: relative;
}

.actors-member-item {
	display: block;
	margin: 0;
	padding: 0;
	text-align: center;
	margin-bottom: 25px;
}

.actors-member-item a {
	width: 100%;
	height: 100%;
	display: block;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.actors-member-item img {
	width: 165px;
	height: 165px;
	object-fit: cover;
	border-radius: 100%;
	margin-bottom: 10px;
}

.actors-member-item span, .actors-member-item a span {
	font-size: 16px;
	color: #fff;
	font-weight: 600;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 260px;
	display: block;
	text-align: center;
	margin: 0 auto;
}

.actors-member-item a:hover span {
	color: #d31027;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.actors-member-single-item {
	border-bottom: 2px solid #202020;
	padding: 20px;
	margin-bottom: 25px;
	overflow: hidden;
}

.actors-member-single-item .actors-member-item span {
	background: rgba(255, 5, 50, 0.6);
	padding: 4px 15px;
	border-radius: 20px;
	color: #fff;
	font-weight: 500;
	margin: 0 auto;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.actors-member-single-item img {
	width: 150px;
	height: 150px;
	object-fit: cover;
	border-radius: 100%;
	display: block;
	margin: 0 auto 15px auto;
}

.actors-member-single-item .actors-member-item {
	margin-bottom: 0px;
}

@media (max-width:767px) {
	.actors-member-item img {
		width: 150px;
		height: 150px;
		display: block;
		margin: 0 auto 15px auto;
	}

	.actors-member-single-item {
		padding-left: 0;
		padding-right: 0;
	}

}

@media (max-width:479px) {
	.actors-member-single-item .actors-member-item span {
		font-size: 14px;
	}

}

@media (max-width:359px) {
	.actors-member-single-item .actors-member-item span {
		font-size: 14px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 230px;
		display: inline-block;
	}

}

.browse_pic_file {
	position: relative;
	display: inline-block;
	cursor: pointer;
	height: 50px;
	float: left;
	margin-right: 10px;
}

.browse_pic_file input {
	width: 20rem;
	margin: 0;
	filter: alpha(opacity=0);
	opacity: 0;
}

.user_pic_view {
	display: inline-block;
}

.user_pic_view img.fileupload_img {
	width: 50px;
	height: 50px;
	margin-top: 0px;
	border: 2px solid #2c273c;
	border-radius: 50px;
}

.user_pic_view .pic_remove {
	margin-left: 5px;
	width: 22px;
	height: 22px;
	display: inline-block;
	text-align: center;
	color: #ffffff;
	line-height: 19px;
	border: 2px solid #fff;
	border-radius: 30px;
	font-size: 10px;
}

.user_pic_view .pic_remove:hover {
	color: #d31027;
	border: 2px solid #d31027;
}

.browse_file_custom {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 5;
	height: 50px;
	padding: 0.5rem 1rem;
	color: #6c757d;
	font-size: 15px;
	font-weight: 700;
	line-height: 2;
	background-color: #201c2d;
	border: 2px solid #2c273c;
	border-radius: 0.25rem;
}

.browse_file_custom::before {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0;
	z-index: 6;
	display: block;
	content: "Browse";
	height: 46px;
	padding: 0.5rem 1rem;
	line-height: 30px;
	color: #ffffff;
	background: linear-gradient(90deg, #eaa538, #d31027);
	border: 0;
	border-radius: 0 4px 4px 0;
	text-transform: uppercase;
	font-size: 14px;
}

.browse_file_custom::after {
	content: "Choose File..";
}

.preloader {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 99999 !important;
	background: radial-gradient(closest-corner, #2a1c50 20%, #180d36 60%, #0d0620 100%);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.preloader .lds-ellipsis {
	display: inline-block;
	position: absolute;
	width: 80px;
	height: 80px;
	margin-top: -40px;
	margin-left: -40px;
	top: 50%;
	left: 50%;
}

.preloader .lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: linear-gradient(60deg, #eaa538, #d31027);
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.preloader .lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
}

.preloader .lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
}

.preloader .lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
}

.preloader.preloader-dark {
	background-color: #000;
}

.preloader.preloader-dark .lds-ellipsis div {
	background-color: #fff;
}

@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}

}

@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0);
	}

}

@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(24px, 0);
	}

}

.header-section ul li:hover > a {
	color: #d31027;
}

header .modal-content {
	border-radius: 0;
}

header .modal-header {
	background-color: #f44336;
	padding: 0;
}

header .modal-header h3 {
	color: #fff;
	font-size: 20px;
	font-weight: 400;
	padding: 10px 15px;
	text-transform: capitalize;
	height: 50px;
	line-height: 29px;
	border: none !important;
}

header .modal-header .close-btn {
	background-color: #d93d3d;
	border: medium none;
	border-radius: 0;
	color: #fff;
	font-size: 33px;
	height: 50px;
	line-height: 33px;
	margin: 0;
	width: 40px;
}

header .modal-body .btn.btn-primary {
	background-color: #f44336;
	border: medium none;
	border-radius: 4px;
	font-size: 18px;
	min-width: 116px;
}

#social-media .modal-header .modal-title {
	font-size: 20px;
	font-weight: 600;
}

.modal-content {
	border: none;
}

.modal-header {
	border-bottom: 1px solid #25324c;
	padding: 1rem 1.5rem;
}

.modal-body {
	padding: 1.5rem;
}

.modal-backdrop.show {
	background: rgba(6, 8, 12, 1);
	opacity: 0.9;
}

.modal-content {
	background: #141d30;
}

.modal-dialog .btn-close {
	box-sizing: content-box;
	width: 26px;
	height: 26px;
	padding: 3px;
	color: #ffffff;
	background: #e8e8e8 url("data:image/svg+xml,%3csvg !string!!string!!string!%3e%3cpath !string!/%3e%3c/svg%3e") center 1em auto no-repeat;
	border: 0;
	border-radius: 30px;
	opacity: 0.9;
	margin-right: 0;
}

.social-media-modal {
	display: inlin-block;
	text-align: center;
}

.social-media-modal ul li {
	display: inline-block;
}

.social-media-modal ul li a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 42px;
	text-align: center;
	margin: 3px;
	border-radius: 50%;
	font-size: 18px;
	color: #ffffff;
	position: relative;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
}

.social-media-modal ul li a.facebook-icon {
	background: #4267b2;
}

.social-media-modal ul li a.twitter-icon {
	background: #00acee;
}

.social-media-modal ul li a.instagram-icon {
	background: #8a3ab9;
}

.social-media-modal ul li a.pinterest-icon {
	background: #e60023;
}

.social-media-modal ul li a.youtube-icon {
	background: #c4302b;
}

.social-media-modal ul li a.whatsapp-icon {
	background: #4fce5d;
}

.social-media-modal ul li a:hover {
	background: #ffffff;
	color: #323232;
}

#trailerModel.stripe-payment-block .modal-body {
	padding: 0 15px;
}

#trailerModel.stripe-payment-block .modal-body #v_player_trailer {
	border-radius: 10px;
}

.dtl-title-block {
	position: relative;
}

.dtl-title-block h2, .dtl-title-block h2 a {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 85%;
}

.dtl-title-block .video-watch-share-item {
	text-align: right;
	float: right;
	position: absolute;
	right: 0;
	margin-top: 0;
}

.dtl-title-block .video-watch-share-item .subscribe-btn-item {
	margin: 0;
}

.eupopup-container {
	background-color: rgba(19, 10, 45, 0.98) !important;
	box-shadow: 0 0px 18px 0 rgba(255, 255, 255, 0.12) !important;
}

.eupopup-closebutton {
	background: #d31027 !important;
}

.eupopup-closebutton:hover, .eupopup-closebutton:active {
	background: #fff !important;
	color: #d31027 !important;
}

.eupopup-button_1, .eupopup-button_2 {
	color: #d31027 !important;
}

.eupopup-button_1:hover, .eupopup-button_2:hover {
	color: #ffffff !important;
}

.show-details-single-video {
	width: 230px !important;
}

@media (max-width:400px) {
	.season-align {
		justify-content: center;
		flex-wrap: wrap;
	}

}